import { configureStore, Middleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REGISTER,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
} from "redux-persist";
import reducers from "./reducers";
import { IRootState } from "./reducers";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { UserActionTypes } from "./currentUser/slice";
import { MenuActionTypes } from "./menu/slice";

const {
  SET_CURRENT_USER_AUTH_STATE,
  DELETE_CURRENT_USER_INFORMATION,
  GET_CURRENT_USER_ROLE_SUCESS,
  SET_CHANGE_PASSWORD_PARAMS,
  SET_CURRENT_USER_INFORMATION,
  SET_AUTH_INFORMATION,
} = UserActionTypes;

const {
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  MENU_CLICK_MOBILE_MENU,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_SET_CLASSNAMES,
} = MenuActionTypes;

const persistConfig = {
  key: "backoffice",
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
};

let persistedReducer = persistReducer<IRootState>(persistConfig, reducers);

let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      createStateSyncMiddleware({
        blacklist: [
          SET_CURRENT_USER_AUTH_STATE,
          DELETE_CURRENT_USER_INFORMATION,
          GET_CURRENT_USER_ROLE_SUCESS,
          SET_CHANGE_PASSWORD_PARAMS,
          SET_CURRENT_USER_INFORMATION,
          MENU_CHANGE_DEFAULT_CLASSES,
          MENU_CHANGE_HAS_SUB_ITEM_STATUS,
          MENU_CLICK_MOBILE_MENU,
          MENU_CONTAINER_ADD_CLASSNAME,
          MENU_SET_CLASSNAMES,
          PERSIST,
          PURGE,
        ],
        whitelist: [SET_AUTH_INFORMATION],
      }) as Middleware
    ),
});

initMessageListener(store);

export let persistor = persistStore(store);

if (module.hot) {
  module.hot.accept("./reducers", () => {
    const nextRootReducer = require("./reducers");
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
