import { PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import {
  getCurrentUserRoleSuccessAction,
  InitialStateType,
  setAuthInformationAction,
  setChangePasswordParamsAction,
  setCurrentUserAuthStateAction,
  setCurrentUserInformationAction,
} from "./types";

export const getCurrentUserRoleSuccessHandler = (
  state: InitialStateType,
  action: getCurrentUserRoleSuccessAction
) => {
  const role = action.payload;
  return { ...state, role };
};

export const setCurrentUserInformationHandler = (
  state: InitialStateType,
  action: setCurrentUserInformationAction
) => {
  const { _id, email, firstName, lastName, temporalPassword, picture } =
    action.payload;
  return {
    ...state,
    _id,
    email,
    firstName,
    lastName,
    temporalPassword,
    picture,
  };
};

export const deleteCurrentUserInformationHandler = (
  state: InitialStateType,
  action: PayloadAction<void>
) => {
  return initialState;
};

export const setAuthInformationHandler = (
  state: InitialStateType,
  action: setAuthInformationAction
) => {
  const { token, refreshToken, expiration, idToken } = action.payload;
  return {
    ...state,
    token,
    refreshToken,
    expiration,
    idToken,
  };
};

export const setCurrentUserAuthStateHandler = (
  state: InitialStateType,
  action: setCurrentUserAuthStateAction
) => {
  const authState = action.payload;
  return { ...state, authState };
};

export const setChangePasswordParamsHandler = (
  state: InitialStateType,
  action: setChangePasswordParamsAction
) => {
  const { id, token } = action.payload;
  return { ...state, urlId: id, urlToken: token };
};
