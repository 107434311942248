import { combineReducers } from "@reduxjs/toolkit";
import currentUser from "./currentUser/slice";
import menu from "./menu/slice";
import settings from "./settings/slice";

const reducers = combineReducers({
  currentUser,
  menu,
  settings,
});

export type IRootState = ReturnType<typeof reducers>;
export type ReducerType = typeof reducers;

export default reducers;
