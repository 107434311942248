export const SERVICE_API_BASES = {
  APP: process.env.REACT_APP_APP_BASE,
  COLLABORATORS: process.env.REACT_APP_API_URL,
  AUTH: process.env.REACT_APP_API_URL_AUTH,
  REPORT: process.env.REACT_APP_API_URL_REPORTS,
  LOANS: process.env.REACT_APP_API_URL_LOANS,
  MARKETING: process.env.REACT_APP_API_URL_MARKETING,
  PROMOTIONS: process.env.REACT_APP_API_URL_PROMOTIONS,
  INTEGRATIONS: process.env.REACT_APP_API_URL_INTEGRATIONS,
  USERS: process.env.REACT_APP_API_URL_USERS,
  TICKETS: process.env.REACT_APP_API_URL_TICKETS,
  SECURITY: process.env.REACT_APP_API_URL_SECURITY,
};
