import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from "../../constants/defaultValues";
import { InitialStateType } from "./types";

const initialState: InitialStateType = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: false, //if you use menu-sub-hidden as default menu type, set value of this variable to false
};

export default initialState;
