import { defaultLocale, localeOptions } from "../../constants/defaultValues";
import { SERVICE_API_BASES } from "../../services/constants";
import { APIS, InitialStateType } from "./types";

const {
  AUTH,
  TICKETS,
  COLLABORATORS,
  USERS,
  MARKETING,
  INTEGRATIONS,
  PROMOTIONS,
  REPORT,
  LOANS,
  SECURITY,
} = APIS;

const getVersion = (base: keyof typeof SERVICE_API_BASES) => {
  const regex = /[^-]+$/; // Get the substring after the last dash
  const matchResult = SERVICE_API_BASES[base]?.match(regex);
  const version =
    matchResult !== null && matchResult !== undefined ? matchResult[0] : "v1";
  return version;
};

const initialState: InitialStateType = {
  locale:
    (localStorage.getItem("currentLanguage") &&
    localeOptions.filter(
      (x) => x.id === localStorage.getItem("currentLanguage")
    ).length > 0
      ? localStorage.getItem("currentLanguage")
      : defaultLocale) || "es",
  apiVersions: {
    AUTH: getVersion(AUTH),
    TICKETS: getVersion(TICKETS),
    COLLABORATORS: getVersion(COLLABORATORS),
    USERS: getVersion(USERS),
    MARKETING: getVersion(MARKETING),
    INTEGRATIONS: getVersion(INTEGRATIONS),
    PROMOTIONS: getVersion(PROMOTIONS),
    REPORT: getVersion(REPORT),
    LOANS: getVersion(LOANS),
    SECURITY: getVersion(SECURITY),
  },
};

export default initialState;
