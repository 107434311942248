import initialState from "./initialState";
import {
  ChangeApiVersionAction,
  InitialStateType,
  LocaleStateAction,
  ResetAPiVersionAction,
} from "./types";

const changeLocaleHandler = (
  state: InitialStateType,
  action: LocaleStateAction
) => {
  return {
    ...state,
    locale: action.payload,
  };
};

const changeApiVersionHandler = (
  state: InitialStateType,
  action: ChangeApiVersionAction
) => {
  return {
    ...state,
    apiVersions: { ...action.payload },
  };
};

const resetApiVersionHandler = (
  state: InitialStateType,
  action: ResetAPiVersionAction
) => {
  return initialState;
};

export { changeLocaleHandler, changeApiVersionHandler, resetApiVersionHandler };
