import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import {
  changeApiVersionHandler,
  changeLocaleHandler,
  resetApiVersionHandler,
} from "./handlers";

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    changeLocale: changeLocaleHandler,
    changeApiVersion: changeApiVersionHandler,
    resetApiVersion: resetApiVersionHandler,
  },
});

export const { changeLocale, changeApiVersion, resetApiVersion } =
  settingsSlice.actions;

export default settingsSlice.reducer;
