import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import {
  changeSelectedMenuHasSubItemsHandler,
  changeDefaultClassnamesHandler,
  clickOnMobileMenuHandler,
  addContainerClassnameHandler,
  setContainerClassnamesHandler,
} from "./handlers";

const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    changeSelectedMenuHasSubItems: changeSelectedMenuHasSubItemsHandler,
    setContainerClassnames: setContainerClassnamesHandler,
    clickOnMobileMenu: clickOnMobileMenuHandler,
    addContainerClassname: addContainerClassnameHandler,
    changeDefaultClassnames: changeDefaultClassnamesHandler,
  },
});

export const {
  changeSelectedMenuHasSubItems,
  setContainerClassnames,
  clickOnMobileMenu,
  addContainerClassname,
  changeDefaultClassnames,
} = menuSlice.actions;

export const MenuActionTypes = {
  MENU_CHANGE_DEFAULT_CLASSES: changeDefaultClassnames.type,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS: changeSelectedMenuHasSubItems.type,
  MENU_CLICK_MOBILE_MENU: clickOnMobileMenu.type,
  MENU_CONTAINER_ADD_CLASSNAME: addContainerClassname.type,
  MENU_SET_CLASSNAMES: setContainerClassnames.type,
};

export default menuSlice.reducer;
