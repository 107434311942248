/*
    Table of Contents

    01. User Login, Logout, Register
    02. Sidebar Menu
    03. Sidebar Sub-Menu
    04. Notifications
    05. Buttons
    06. Error 
    07. Executive
    08. Admin Panel
    09. Marketing 
    10. Development 
*/
// eslint-disable-next-line
export default {
  /* 01. User Login, Logout, Register */
  "user.login-title": "Iniciar sesión",
  "user.change-password-title": "Ingrese nueva contraseña",
  "user.forgot-password-title": "Se te olvidó tu contraseña",
  "user.change-password-unmatched":
    "Las contraseñas no coindicen. Por favor verifique!",
  "user.change-password-description":
    "La contraseña debe contener un mínimo de 8 caracteres, 1 mayúscula, 1 número y 1 símbolo (!@#$%^&*.)",
  "user.forgot-password-description":
    "Enviarás una solicitud de contraseña temporal",
  "user.login.input.required.email":
    "Por favor ingresá tu dirección de correo electrónico",
  "user.login.input.invalid.email": "Correo electrónico inválido",
  "user.login.input.required.password": "Por favor ingresá tu contraseña",
  "user.login.input.invalid.password": "Contraseña inválida",
  "user.login-button": "ENVIAR",
  "user.email": "E-mail",
  "user.password": "Contraseña",
  "user.confirm-password": "Repetir contraseña",
  "user.forgot-password-question": "¿Olvidaste tu contraseña?",
  "search.noresults": "No existen coincidencias",
  "user.welcome": "Bienvenido/a {name}",
  "user.welcome.sub-text": "Al sistema Back Office de Billetera Mango",
  "user.welcome.role-name": "Rol: {roleName}",

  /* Topnav */
  "topnav.dropdown.logout": "Cerrar sesión",
  "topnav.dropdown.photo": "Foto del perfil",
  "topnav.profile-modal.invalid-file":
    "No se ha podido mostrar la imagen. Verifique que el nombre del archivo contenga caracteres válidos o que el formato sea soportado",
  "topnav.profile-modal.image-conversion-error":
    "Se ha producido un error al convertir la imagen",
  "topnav.profile-modal.no-2d-content-error": "No es un contenido 2d",
  "topnav.dev-options": "Opciones de desarrollador",

  /* 02. Sidebar Menu */
  "menu.app": "Inicio",
  "menu.colaboradores": "Colaboradores",
  "menu.ordenar-categorias": "Categorías de servicios",
  "menu.influencer": "Influencer",
  "menu.tickets": "Tickets",
  "menu.know-your-customer": "Usuarios Mango",
  "menu.know-your-customer-pending": "Cuentas pendientes de aprobación",
  "menu.know-your-customer-approved": "Cuentas de usuarios aprobadas",
  "menu.marketing": "Marketing",
  "menu.commerce": "Comercios",
  "menu.integrations": "Integraciones",
  "menu.notificaciones": "Notificaciones",
  "menu.integraciones": "Integraciones",
  "menu.infonet": "INFONET",
  "menu.vpos": "VPOS",
  "menu.tufi": "Tu Financiera",
  "menu.pesa": "PESA",
  "menu.categorias": "Categorías",
  "menu.reports": "Reportes",
  "menu.reportes": "Reportes",
  "menu.detalles": "Detalles",
  "menu.development": "Desarrollo",
  "menu.admin-panel": "Panel de Administración",
  "menu.mango-comercio": "Mango Comercio",

  /* Referidos */
  "referrals.header": "Lista de referidos",
  "referrals.download-list-label": "Descargar reporte",
  "referrals.downloading-list-label": "Descargando archivo...",
  "referrals.modal.go-to-registration": "IR A SEGUIMIENTO",
  "referrals.modal.contact": "CONTACTAR",
  "referrals.modal.title": "Estados del usuario",

  /* Campañas */
  "campaigns.header": "Campañas",
  "campaigns.header.create-button": "CREAR CAMPAÑA",
  "edit-campaign.header": "Editar campaña",
  "create-campaign.header": "Crear campaña",
  "campaigns-empty-list": "La lista de campañas se encuentra vacía.",
  "campaigns.list.reward-value":
    "Anfitrión: {hostReward} | Invitado: {guestReward}",
  "campaigns.list.goal-unlimited": "Sin límites",
  "campaign.card-title": "Detalles de la campaña",
  "campaign.first-step.name-label": "Nombre de la campaña",
  "campaign.first-step.days": "Días",
  "campaign.first-step.hours": "Horas",
  "campaign.first-step.minutes": "Minutos",
  "campaign.first-step.date-from-label": "Periodo de Inicio",
  "campaign.first-step.error.minimun-minute":
    "Se debe ingresar al menos un tiempo de 30 minutos",
  "campaign.first-step.error.only-integer":
    "Solo se permiten números enteros mayores o iguales a cero",
  "campaign.first-step.date-time-to-label": "Finalización",
  "campaign.first-step.time-limit-label": "Tiempo límite para registrarse",
  "campaign.first-step.reward-label": "Monto de la recompensa",
  "campaign.first-step.host-reward-label": "El anfitrión recibe",
  "campaign.first-step.guest-reward-label": "El invitado recibe",
  "campaign.first-step.advanced-settings-label": "Configuraciones avanzadas",
  "campaign.first-step.advanced-settings-input-label": "Selecciona una opción",
  "campaign.first-step.advanced-settings-reduce-amount": "Monto a deducir",
  "campaign.first-step.bottom-reward-type": "Tipo: ",
  "campaign.first-step.bottom-reward-variation-type": "Monto a deducir: ",
  "campaign.first-step.bottom-max-reward-variation-type":
    "Monto máximo a deducir: ",
  "campaign.first-step.priority-label": "Definir prioridad",
  "campaign.first-step.advanced-settings-max-reduce-amount":
    "Monto máximo a deducir acumulado",
  "campaign.first-step.advanced-settings-invalid": "NO VÁLIDO",
  "campaign.first-step.advanced-settings-reward-type": "Tipo de recompensa",
  "campaign.first-step.advanced-settings-reward-type-label":
    "Los invitados que ignoren pierden parte de su recompensa y se crea un acumulativo para el que complete su invitación.",
  "campaign.first-step.image-label": "Ingrese los datos de la campaña",
  "campaign.fifth-step.image-label": "Lleguemos a la meta!",
  "campaign.first-step.deduce-amount-description":
    "El monto a deducir tiene que ser menor o igual a la recompensa individual del invitado.",
  "campaign.first-step.max-deduce-amount-description":
    "Indica el monto máximo a deducir al invitado que no cumpla con las condiciones",
  "campaign.first-step.reward-error":
    "El monto de recompensa debe ser mayor a cero",
  "campaign.first-step.deduce-amount-greater-to-max":
    "El monto máximo a deducir no debe ser menor al monto a deducir",
  "campaign.confirmation-modal.description":
    "Estás intentando editar el monto que recibe el invitado. Si continúa se van a resetear los cambios hechos en la configuración avanzada",
  "campaign.first-step.guest-reward-not-exist":
    "Debe ingresar el monto que recibe el invitado para poder configurar",
  "campaign.first-step.cero-deduce-amount":
    "El monto a deducir debe ser mayor a cero",
  "campaign.first-step.cero-max-deduce-amount":
    "El monto máximo acumulado a deducir debe ser mayor a cero",
  "campaign.first-step.deduce-amount-greater":
    "El monto a deducir no debe exceder el monto que recibe el invitado",
  "campaign.first-step.max-deduce-amount-greater":
    "El monto máximo acumulado a deducir no debe exceder el monto que recibe el invitado",
  "campaign.first-step.custom-error":
    "Solo uno, el anfitrion o invitado puede recibir monto cero, no ambos",
  "campaign.first-step.reward-sum-uneven":
    "La suma del monto que recibe el anfitrión e invitado debe ser igual al monto de recompensa",
  "campaign.second-step.invitations-limit-label":
    "Delimitar cantidad de usuarios",
  "campaign.second-step.invitations-per-host": "Invitados por usuario",
  "campaign.second-step.invited-count": "Usuarios invitados",
  "campaign.second-step.unlimited-checkbox": "Sin límites de invitados",
  "campaign.second-step.who-can-invite": "¿Quiénes pueden invitar?",
  "campaign.second-step.choose-an-option": "Elige una opción",
  "campaign.second-step.@-list-label":
    "Pega una lista de @ separados por comas (,)",
  "campaign.second-step.invalid-file": "El formato del archivo es incorrecto",
  "campaign.second-step.invalid-file-format":
    "Verifique si el formato del archivo es inválido",
  "campaign.second-step.invited-description-title":
    "Puedes cargar de 3 formas los usuarios:",
  "campaign.second-step.invited-description-one":
    "1) Escribí una lista de @ separados por comas (,).",
  "campaign.second-step.invited-description-two":
    "2) Carga un archivo .csv la fila 1 la detecta como encabezado.",
  "campaign.second-step.invited-description-three":
    "3) Carga un archivo .txt con una lista de @ separados por comas (,).",
  "campaign.second-step.detected-user-list.dynamic":
    "Columna detectada: {column}",
  "campaign.second-step.detected-user-list.static": "Lista de usuarios",
  "campaign.second-step.detected-users-title": "Usuarios detectados",
  "campaign.second-step.negative-invitations-limit":
    "La cantidad de usuarios no puede ser negativo o cero",
  "campaign.second-step.negative-invitations-per-host":
    "La cantidad de invitados por usuario no puede ser negativo o cero",
  "campaign.second-step.users-added-count": "Se agregaron {count} @usuarios.",
  "campaign.second-step.view-list-label": "Ver lista",
  "campaign.second-step.user-list-placeholder": "@ruben,@carlos,@jonas",
  "campaign.second-step.limit-by-@-label": "Delimitar por @",
  "campaign.third-step.conditions-label": "Condiciones",
  "campaign.third-step.select-function-label": "Seleccione una función",
  "campaign.third-step.amount-to-achieve-label": "Monto que debe alcanzar",
  "campaign.third-step.conditions-option-label":
    "El <Contacto 📞 invitado> debe culminar el registro y su estado en KYC debe estar como Verificado.",
  "campaign.four-step.banner-input-label":
    "Imagen para el banner superior de la campaña",
  "campaign.four-step.popup-input-label": "Imagen para el POP-UP al tocar",
  "campaign.four-step.input-placeholder": "Subí una imagen",
  "campaign.four-step.banner-input-description":
    "La imagen debe tener una relación de aspecto de 16:9",
  "campaign.four-step.popup-input-description":
    "La imagen debe tener una relación de aspecto de 9:16",
  "campaign.four-step.preview-label": "Ver vista previa",
  "campaign.four-step.phone-content-title": "Mis referidos",
  "campaign.four-step.image-not-uploaded-label":
    "Asegúrate de subir una imagen",
  "campaign.four-step.static-reward-label": "Invitación normal",
  "campaign.four-step.dynamic-reward-label": "Invitación con puja",
  "campaign.four-step.zero-dynamic-reward-label": "Invitación con puja cero",
  "campaign.four-step.whatsapp-message-input-label":
    "Mensaje de invitación Whatsapp",
  "campaign.four-step.sms-message-input-label": "Mensaje de invitación SMS",
  "campaign.four-step.form-error.not-found-variables":
    "Las siguientes variables no son válidas: {variables}",
  "campaign.four-step.form-error.not-found-variable":
    "La siguiente variable no es válida: {variables}",
  "campaign.four-step.form-error.sms-length-exceded":
    "La longitud del mensaje no debe exceder {maxLength} caracteres",
  "campaign.four-step.variables-modal-subtitle":
    "Seleccione las variables que deseas agregar",
  "campaign.four-step.variables-modal-title": "Variables",
  "campaign.four-step.message-length-counter": "{count}/{maxLength}",
  "campaign.four-step.add-variable-label": "Agregar variable",
  "campaign.fifth-step.inactive": "Inactivo",
  "campaign.fifth-step.create-title": "Campaña creada",
  "campaign.fifth-step.edit-title": "Campaña editada",
  "campaign.fifth-step.reward-value-title": "Valor de la recompensa",
  "campaign.fifth-step.host-reward-title": "El que invita recibe:",
  "campaign.fifth-step.guest-reward-title": "El invitado recibe:",
  "campaign.fifth-step.participants-title": "Participantes",
  "campaign.fifth-step.invitations-limit-title": "Límite de invitados",
  "campaign.fifth-step.invitations-per-host-limit-title":
    "Límite de invitados por usuario",
  "campaign.fifth-step.conditions-title": "Condiciones",
  "campaign.fifth-step.unlimited-invitations-label": "Sin límites",
  "campaign.fifth-step.everyone-label": "Todos los usuarios",
  "campaign.delete-modal-body":
    "Al eliminar la campaña se eliminarán las invitaciones pendientes que no se hayan cobrado aún. ¿Estás seguro de que deseas eliminar la campaña?",

  /* F.A.Q  */
  "FAQ-heading": "F.A.Q",
  "FAQ-create-button": "Crear nuevo",
  "FAQ-tab-all": "Todos",
  "FAQ-delete-modal-body": "¿Estás seguro que deseas eliminar este FAQ?",

  /* 03. Sidebar Sub-Menu */
  "sub-menu.colaboradores": "Lista de colaboradores",
  "sub-menu.roles": "Roles",
  "sub-menu.admin-panel.transactions": "Transferencias",
  "sub-menu.admin-panel.channels": "Canales",
  "sub-menu.admin-panel.configurations": "Configuraciones",
  "sub-menu.admin-panel.roles": "Roles",
  "sub-menu.identity-verification": "Verificación de identidad",
  "sub-menu.fast-verification": "Verificación rápida",
  "sub-menu.account-extension": "Extensión de cuentas",
  "sub-menu.registry-tracing": "Seguimiento de registro",
  "sub-menu.commerce.commerce": "Comercios",
  "sub-menu.marketing.notifications": "Notificaciones APP",
  "sub-menu.marketing.events": `Banners "novedades"`,
  "sub-menu.marketing.referrals": "Referidos",
  "sub-menu.marketing.referrals-list": "Lista de referidos",
  "sub-menu.marketing.campaigns": "Campañas",
  "sub-menu.commerce.promos": "Promociones",
  "sub-menu.commerce.list": "Lista de comercios",
  "sub-menu.commerce.categories": "Categorías de comercios",
  "sub-menu.commerce.tags": "Etiquetas",
  "sub-menu.marketing.push": "Lista de notificaciones",
  "sub-menu.marketing.influencer": "Influencer",
  "sub-menu.marketing.FAQ": "F.A.Q",
  "sub-menu.marketing.promos": "Promociones",
  "sub-menu.integrations.list": "Lista de integraciones",
  "sub-menu.integrations.configurations": "Configuraciones",
  "sub-menu.integrations.conectivity": "Conectividad",
  "sub-menu.integrations.service-availability": "Disponibilidad de servicios",
  "sub-menu.integrations.bank-entities": "Entidades bancarias",
  "sub-menu.marketing.segments": "Segmentos",
  "sub-menu.development.push": "Notificaciones Push",
  "sub-menu.development.push-types": "Tipos de Push",
  "sub-menu.mango-comercio.comercios": "Comercios",
  "sub-menu-dropdown.admin-panel.mango": "Mango",
  "sub-menu-dropdown.admin-panel.dashboard": "Dashboard",
  "sub-menu.reports": "Reportes",
  "sub-menu.reports.endpoints": "Endpoints",
  "sub-menu.reports.identity-verification": "Verificación de identidad",
  "sub-menu.reports.transactions.title": "Transacciones",
  "sub-menu.reports.transactions": "Reportes de transacciones",
  "sub-menu.reports.transactions.detail": "Detalles de transacciones",
  "sub-menu.reports.credit-lines-reports": "Reportes de Créditos",
  "sub-menu.reports.users-history": "Generar historial de usuario",
  "sub-menu.reports.user-statement": "Extracto de cuenta",
  "sub-menu.devices-requests": "Solicitud de vinculación de dispositivos",
  "sub-menu.register-requests": "Solicitud de registro",
  "sub-menu.requests-verification": "Verificación de solicitud",
  "sub-menu.reports.my-credit": "Mi Crédito",

  /* 04. Notifications */
  "notification.error.fetch": "La lista no ha podido ser actualizada",
  "notification.error.signin": "Se ha producido un error",
  "notification.error.title": "Se ha producido un error",
  "notification.success.post": "Operación exitosa",
  "notification.warning.unapplied":
    "Hay cambios no aplicados en verificación de datos de usuario",
  "notification.warning.title": "Aviso",
  "notification.warning.noPendings": "No existen notificaciones pendientes",
  "notification.success.patch": "Operación exitosa",
  "notification.success.put": "Operación exitosa",
  "notification.error.patch": "Hubo un error, no se pudo realizar la operación",
  "notification.success.delete": "Operación exitosa",
  "notification.error.delete":
    "Hubo un error, no se pudo realizar la operación",
  "notification.error": "Hubo un error, no se pudo realizar la operación",
  "notification.success": "Operación exitosa",
  "notification.error.id": "No se ha proporcionado el campo ID",
  "notification.error.id.status":
    "No se ha proporcionado el campo ID o el campo status",
  "notification.black-list-alert":
    "Alerta de seguridad. Coincidencias del usuario en LISTA NEGATIVA.",
  "notification.user-in-black-list":
    "Haz verificado que el usuario forma parte de la lista negativa.",
  "notification.user-not-in-black-list-verified":
    "Haz verificado que el usuario no forma parte de la lista negativa.",
  "notification.user-in-black-list-question":
    "¿El usuario figura en la lista negativa?",
  "notification.yes-button": "Si",
  "notification.no-button": "No",
  "notification.not-sure-button": "No estoy seguro",
  "notification.copy.firstName": "Nombre copiado",
  "notification.copy.lastName": "Apellido copiado",
  "notification.copy.documentNumber": "Número de documento copiado",
  "notification.resize-image-error": "Error al redimensionar la imagen",

  /* Labels */
  "label.pending": "Pendiente",
  "label.rejected-document": "Documento rechazado",
  "label-temporal-rejected-document": "Rechazo temporal",
  "label.names": "Nombres",
  "label.name": "Nombre",
  "label.lastnames": "Apellidos",
  "label.fullname": "Nombre y Apellido",
  "label.complete-name": "Nombre completo",
  "label.short-name": "Nombre corto",
  "label.complete-lastname": "Apellido completo",
  "label.telephone": "Teléfono",
  "label.ci": "CI",
  "label.contribution-type": "Tipo de aporte",
  "label.all-contributions": "Todos los aportes",
  "label.profession": "Profesión",
  "label.laboral-activity": "Actividad laboral",
  "label.work-place": "Lugar de trabajo",
  "label.income": "Ingreso",
  "label.ips": "I.P.S",
  "label.iva": "I.V.A",
  "label.work-certificate": "Certificado laboral",
  "label.liquidation-salary": "Liquidación de salario",
  "label.createdAt": "Fecha de creación",
  "label.activatedAt": "Fecha de activación",
  "label.finalizedAt": "Fecha de finalización",
  "label.ingressedAt": "Fecha de ingreso",
  "label.assignationDate": "Fecha de asignación",
  "label.time-elapsed": "Tiempo transcurrido",
  "label.programmed": "Programado",
  "label.event": "Evento",
  "label.promotion": "Promoción",
  "label.image": "Imagen",
  "label.pop-up": "POP-UP",
  "label.condition": "Condición",
  "label.period": "Periodo",
  "label.reward-value": "Valor de recompensa",
  "label.goal": "Meta",
  "label.email": "Correo",
  "label.email-variant": "Correo electrónico",
  "label.identification": "Cédula de Identidad",
  "label.departament": "Departamento",
  "label.city": "Ciudad",
  "label.address": "Dirección",
  "label.birthdate": "Fecha de nacimiento",
  "label.invitation-date": "Fecha de invitación",
  "label.active-token": "Token activo",
  "label.unlocked": "Liberado",
  "label.loading-date": "Fecha de carga",
  "label.approved-a": "Aprobada",
  "label.rejected": "Rechazado",
  "label.rejected-a": "Rechazada",
  "label.conected": "Conectado",
  "label.disconnected": "Desconectado",
  "label.verified": "Verificado",
  "label.reported": "Reportado",
  "label.discarded": "Descartado",
  "labels.no-coincidences": "Sin coincidencias",
  "label.sent": "Enviado",
  "label.inqueue": "Encolado",
  "label.sending-date": "Fecha de envío",

  /* 05. Buttons */
  "button.cancel": "CANCELAR",
  "button.view-next": "VER SIGUIENTE",
  "button.view-next-user": "VER SIGUIENTE USUARIO",
  "button.view-changes": "VER CAMBIOS",
  "button.continue": "CONTINUAR",
  "button.unlock": "LIBERAR",
  "button.save": "GUARDAR",
  "button.reopen-ticket": "REABRIR TICKET",
  "button.reopen": "REABRIR",
  "button.upload-image": "SUBIR IMAGEN",
  "button.interrupt": "INTERRUMPIR",
  "button.update-and-continue": "ACTUALIZAR Y CONTINUAR",
  "button.just-update": "SOLO ACTUALIZAR",
  "button.reject-permanently": "RECHAZO PERMANENTE",
  "button.view-history": "VER HISTORIAL",
  "button.restrict-access": "RESTRINGIR ACCESO",
  "button.allow-access": "PERMITIR ACCESO",
  "button.accept": "ACEPTAR",
  "button.view-list": "VER EN LISTA",
  "button.create": "CREAR",
  "button.reload": "RECARGAR",
  "button.validate-number": "Validar número",
  "button.edit": "EDITAR",
  "button.reject": "RECHAZAR",
  "button.rejected": "RECHAZADO",
  "button.approve": "APROBAR",
  "button.reject-request": "RECHAZAR SOLICITUD",
  "button.reject-documents": "RECHAZAR DOCUMENTOS",
  "button.approved": "APROBADO",
  "button.next": "SIGUIENTE",
  "button.upload": "Upload",
  "button.return": "VOLVER",
  "button.invite": "INVITAR",
  "button.clear": "LIMPIAR",
  "button.download": "DESCARGAR",
  "button.delete": "ELIMINAR",
  "button.add-photo": "AÑADIR FOTO",
  "button.resend": "REENVIAR",
  "button.re-establish": "REESTABLECER",
  "button.activate": "ACTIVAR",
  "button.update": "ACTUALIZAR",
  "button.new": "AGREGAR NUEVO",
  "button.add-with-counter": "AGREGAR ({counter})",
  "button.add": "AGREGAR",
  "button.close": "CERRAR",
  "button.open": "ABRIR",
  "button.add-value": "AÑADIR CAMPO",
  "button.add-commerce": "AGREGAR COMERCIOS",
  "button.add-filter": "AÑADIR FILTRO",
  "button.add-condition": "AÑADIR CONDICIÓN",
  "button.apply": "APLICAR",
  "button.create-ticket": "CREAR TICKETS",
  "button.send-push-test": "ENVIAR PRUEBA",
  "button.send-email": "ENVIAR CORREO",
  "button.confirm": "CONFIRMAR",
  "button.view-ticket": "VER TICKET",
  "button.upload-file": "Subir",
  "button.copy": "Copiar",
  "button.sync-services": "Sincronizar servicios",

  /* 06. Error  */
  "error.get-bind-request-list":
    "No se pudieron obtener las solicitudes de vinculación",
  "error.couldnt-assign-as-trusted":
    "No se pudo asignar como confiable el dispositivo",
  "error.couldnt-complete-operation-on-device":
    "No se pudo realizar operación deseada sobre dispositivo",
  "error.couldnt-verify-request":
    "No se pudo verificar la solicitud de vinculación",
  "error.couldnt-reject-request":
    "No se pudo rechazar la solicitud de vinculación",
  "error.get-bind-request": "No se pudo obtener la solicitud de vinculación",
  "error.could-not-get-request-history":
    "No se pudo obtener el historial de solicitud",
  "error.could-not-get-states-history":
    "No se pudo obtener el historial de estados",
  "error.could-not-get-photos-history":
    "No se pudo obtener el historial de fotos",
  "error.derive-to-fraud": "No se pudo derivar a fraudes",
  "error.get-ticket-comments":
    "No se pudieron obtener los comentarios del ticket",
  "error.get-account-statements":
    "No se pudieron obtener los extractos de cuenta",
  "error.get-ticket-errors": "No se pudieron obtener los errores del ticket",
  "error.get-transactions-history":
    "No se pudo obtener el historial de transacciones del ticket",
  "error.update-ticket": "No se pudo actualizar el ticket",
  "error.get-tickets": "No se pudieron obtener los tickets",
  "error.create-ticket": "No se pudo crear el ticket",
  "error.get-user": "No se pudo obtener el usuario",
  "error.get-ticket-details": "No se pudo obtener los detalles del ticket",
  "error.block-device": "No se pudo desbloquear el dispositivo",
  "error.validate-phone-number": "No se pudo validar el número de teléfono",
  "error.get-ticket-categories": "No se pudo obtener las categorías del ticket",
  "error.get-ticket-devices":
    "No se pudieron obtener los dispositivos del ticket",
  "error.update-black-list-state":
    "No se pudo actualizar el estado de la lista negativa",
  "error.send-observation": "No se pudo enviar la observación",
  "error.derive-to-compliance": "No se pudo derivar a Cumplimiento al usuario",
  "error.get-user-balance": "No se pudo obtener el balance del usuario",
  "error.get-nationalities": "No se pudieron obtener las nacionalidades",
  "error.update-user-state": "No se pudo actualizar el estado del usuario",
  "error.get-mango-users": "No se pudieron obtener los usuarios de mango",
  "error.update-user-access": "No se pudo actualizar el acceso del usuario",
  "error.change-phone-number": "No se pudo cambiar el número de teléfono",
  "error.change-photo-state": "No se pudo cambiar el estado de la foto",
  "error.get-photo-upload-history":
    "No se pudo obtener el historial de carga de fotos",
  "error.get-users-list": "No se pudo obtener la lista de usuarios",
  "error.get-errors-log": "No se pudo obtener el log de errores",
  "error.get-devices": "No se pudieron obtener los dispositivos",
  "error.user-details": "No se pudo obtener los detalles del usuario",
  "error.get-comments": "No se pudieron obtener los comentarios",
  "error.send-comment": "No se pudo enviar el comentario",
  "error.uploading-document": "No se pudo subir el documento",
  "error.get-user-data": "No se pudieron obtener los datos del usuario",
  "error.update-user-photos": "No se pudieron actualizar las fotos del usuario",
  "error.update-user-data": "No se pudieron actualizar los datos del usuario",
  "error.error-label": "Error",
  "error.resource-object-does-not-have-property":
    "Si el objeto del recurso no tiene la propiedad `{property}`, el parámetro `{param}` es obligatorio",
  "error.edited-object-must-be-specified":
    "Se debe especificar el objeto de recurso a ser editado",
  "error.parameter-required": "El parámetro `{param}` es requerido",
  "error.try-again-error":
    "Hemos tenido un problema. Por favor inténtelo nuevamente",
  "error.session-expired-error": "Sesión expirada, vuelva a iniciar su sesión",
  "error.no-response-error": "No se ha obtenido ninguna respuesta",
  "error.error-title": "Vaya, parece que ha ocurrido un error!",
  "error.color-picker.color": "El color ingresado es inválido",
  "error.error-code": "Código de error",
  "error.go-back-home": "REGRESAR A INICIO",
  "error.permissions-error": "No tiene permisos para esta acción",
  "error.file-exceeds-limit":
    "El archivo supera el tamaño permitido de 4MB. Volvé a intentarlo.",
  "error.total-size-exceeds-limit":
    "Error de carga. Superaste límite total de 4MB.",
  "error.file-type-not-allowed":
    " Tipo de archivo incorrecto. Solo se permiten archivos IMG, PNG, y PDF.",
  "error.files-exceeds-qty-limit":
    "Superaste el límite de archivos seleccionados",
  "error.file-upload-qty-warning-body":
    "Podés seleccionar hasta 3 archivos \n para enviar.",
  "error.sending-commentary-title": "No se puede enviar el comentario",
  "error.sending-commentary-body-text":
    "Por favor, volvé a cargar los archivos con error para enviar el comentario.",
  "error.could-not-start-session": "No se pudo iniciar sesión",
  "error.could-not-perform-operation": "No se pudo realizar la operación",
  "error.could-not-change-profile-photo":
    "No se pudo cambiar la foto de perfil",
  "error.could-not-regenerate-password": "No se pudo regenerar la contraseña",
  "error.could-not-get-colaborator": "No se pudo obtener el colaborador",
  "error.could-not-get-colaborators":
    "No se pudieron obtener los colaboradores",
  "error.could-not-get-departments-and-cities":
    "No se pudieron obtener los departamentos y ciudades",
  "error.could-not-get-roles": "No se pudieron obtener los roles",
  "error.could-not-get-permissions": "No se pudieron obtener los permisos",
  "error.could-not-save-colaborator": "No se pudo guardar el colaborador",
  "error.could-not-save-role": "No se pudo guardar el rol",
  "error.could-not-edit-role": "No se pudo editar el rol",
  "error.could-not-edit-colaborator": "No se pudo editar el colaborador",
  "error.could-not-delete-role": "No se pudo eliminar el rol",
  "error.could-not-delete-colaborator": "No se pudo eliminar el colaborador",
  "error.could-not-reactivate-colaborator":
    "No se pudo reactivar el colaborador",
  "error.could-not-block-unblock-colaborator":
    "No se pudo bloquear/desbloquear el colaborador",
  "error.could-not-change-state-of-services":
    "No se pudo cambiar el estado de los servicios",
  "error.could-not-edit-motive": "No se pudo editar el motivo",
  "error.could-not-get-responsibles": "No se pudieron obtener los responsables",
  "error.could-not-get-service-history":
    "No se pudo obtener el historial de servicios",
  "error.could-not-delete-category": "No se pudo eliminar la categoría",
  "error.could-not-edit-category-service":
    "No se pudo editar la categoría de servicio",
  "error.could-not-delete-category-service":
    "No se pudo eliminar el servicio de la categoría",
  "error.could-not-change-service-state":
    "No se pudo cambiar el estado del servicio",
  "error.could-not-edit-category": "No se pudo editar la categoría",
  "error-could-not-save-category": "No se pudo guardar la categoría",
  "error.could-not-sort-infonet-categories":
    "No se pudieron ordenar las categorías de infonet",
  "error.could-not-update-notifications-settings":
    "No se pudo actualizar la configuración de las notificaciones",
  "error.could-not-resend-notifications":
    "No se pudo reenviar las notificaciones",
  "error.could-not-get-pending-notifications":
    "No se pudieron obtener las notificaciones pendientes",
  "error.could-not-modify-setting": "No se pudo modificar la configuración",
  "error.could-not-get-infonet-settings":
    "No se pudieron obtener las configuraciones de infonet",
  "error.could-not-get-infonet-services":
    "No se pudieron obtener los servicios de infonet",
  "error.could-not-get-infonet-categories":
    "No se pudieron obtener las categorías de infonet",
  "error.could-not-get-integrations":
    "No se pudieron obtener las integraciones",
  "error.could-not-get-settings": "No se pudieron obtener las configuraciones",
  "error.could-not-create-setting": "No se pudo crear la configuración",
  "error.could-not-change-setting-state":
    "No se pudo cambiar el estado de la configuración",
  "error.could-not-edit-setting": "No se pudo editar la configuración",
  "error.could-not-get-integrations-state-history":
    "No se pudo obtener el historial de estado de la integraciones",
  "error.could-not-get-growth-history":
    "No se pudo obtener el Historial de Crecimiento",
  "error.could-not-get-user-credit-details":
    "No se pudo obtener los detalles de crédito del usuario",
  "error.could-not-synchronize-user-credit-details":
    "No se pudo sincronizar los detalles de crédito del usuario",
  "error.could-not-download-credits-report":
    "No se pudo descargar el reporte de créditos",
  "error.could-not-get-actions": "No se pudieron obtener las acciones",
  "error.could-not-update-campaign": "No se pudo actualizar la campaña",
  "error.could-not-delete-influencer": "No se pudo eliminar el influencer",
  "error.could-not-get-campaigns-data":
    "No se pudieron obtener los datos de las campañas",
  "error.could-not-get-variables-and-templates":
    "No se pudieron obtener las variables y los templates",
  "error.could-not-get-referal-users":
    "No se pudieron obtener los usuarios referidos",
  "error.could-not-get-filters": "No se pudieron obtener los filtros",
  "error.could-not-get-faqs-list": "No se pudo obtener el listado de FAQs",
  "error.could-not-edit-influencer": "No se pudo editar el influencer",
  "error.could-not-upload-evidence": "No se pudo subir la evidencia",
  "error.could-not-get-influencers": "No se pudieron obtener los influencers",
  "error.could-not-get-segments-filters":
    "No se pudieron obtener los filtros de segmentos",
  "error.could-not-delete-segment": "No se pudo eliminar el segmento",
  "error.could-not-save-segment": "No se pudo guardar el segmento",
  "error.could-not-get-segments": "No se pudieron obtener los segmentos",
  "error.could-not-delete-notification-push":
    "No se pudo eliminar la notificación push",
  "error.could-not-get-notifications-push":
    "No se pudieron obtener las notificaciones push",
  "error.could-not-edit-banner-state": "No se pudo editar el estado del banner",
  "error.could-not-save-faq": "No se pudo guardar el FAQ",
  "error.could-not-edit-faq": "No se pudo editar el FAQ",
  "error.could-not-edit-banner": "No se pudo editar el banner",
  "error.could-not-save-banner": "No se pudo guardar el banner",
  "error.could-not-get-banners": "No se pudieron obtener los banners",
  "error.could-not-get-deeplinks": "No se pudieron obtener los deeplinks",
  "error.could-not-delete-banner": "No se pudo eliminar el banner",
  "error.could-not-delete-faq": "No se pudo eliminar el FAQ",
  "error.could-not-test-push": "No se pudo testear el push",
  "error.could-not-get-reward-conditions":
    "No se pudieron obtener las condiciones de recompensa",
  "error.could-not-get-campaigns": "No se pudieron obtener las campañas",
  "error.could-not-validate-nicknames": "No se pudo validar los nicknames",
  "error.could-not-change-campaign-state":
    "No se pudo cambiar el estado de la campaña",
  "error.could-not-create-campaign": "No se pudo crear la campaña",
  "error.could-not-delete-campaign": "No se pudo eliminar la campaña",
  "error.could-not-save-push": "No se pudo guardar el push",
  "error.could-not-get-tags": "No se pudo obtener las etiquetas",
  "error.could-not-get-promotions": "No se pudo obtener las promociones",
  "error.could-not-get-promotion": "No se pudo obtener la promoción",
  "error.id-property-required": "La propiedad id es requerida",
  "error.could-not-get-category": "No se pudo obtener la categoría",
  "error.could-not-get-categories": "No se pudieron obtener las categorías",
  "error.could-not-get-shops": "No se pudo obtener los comercios",
  "error.could-not-services": "No se pudieron obtener los servicios",
  "error.could-not-get-entities": "No se pudieron obtener las entidades",
  "error.could-not-get-service-categories":
    "No se pudieron obtener las categorías de servicios",
  "error.could-not-add-shops-to-category":
    "No se pudieron agregar los comercios a la categoría",
  "error.could-not-delete-shop": "No se pudo eliminar el comercio",
  "error.could-not-delete-promotion": "No se pudo eliminar la promoción",
  "error.could-not-delete-shop-from-category":
    "No se pudo eliminar el comercio de la categoría",
  "error.could-not-delete-tag": "No se pudo eliminar la etiqueta",
  "error.could-not-create-shop": "No se pudo crear el comercio",
  "error.could-not-creat-promotion": "No se pudo crear la promoción",
  "error.could-not-create-tag": "No se pudo crear la etiqueta",
  "error.could-not-update-shop": "No se pudo actualizar el comercio",
  "error.could-not-edit-promotion": "No se pudo editar la promoción",
  "error.could-not-update-category": "No se pudo actualizar la categoría",
  "error.could-not-update-tag": "No se pudo actualizar la etiqueta",
  "error.could-not-get-account-statement-report":
    "No se pudo obtener el reporte de extracto de cuenta",
  "error.could-not-get-promotions-report":
    "No se pudo obtener el reporte de promociones",
  "error.could-not-generate-transaction-report":
    "No se pudo generar el reporte de transacción",
  "error.could-not-get-transactions-filters":
    "No se pudo obtener los filtros de transacciones",
  "error.could-not-get-endpoint-report":
    "No se pudo obtener el reporte de endpoints",
  "error.could-not-get-users-frecuency":
    "No se pudo obtener la frecuencia de usuarios",
  "error.could-not-get-users-report-filters":
    "No se pudieron obtener los filtros de reporte de usuarios",
  "error.could-not-get-errors-report":
    "No se pudo obtener el reporte de errores",
  "error.could-not-get-users-per-month-report":
    "No se pudo obtener el reporte de usuarios por mes",
  "error.could-not-get-users-report":
    "No se pudo obtener el reporte de usuarios",
  "error.could-not-get-transactions-per-state":
    "No se pudieron obtener las transacciones por estado",
  "error.could-not-get-fav-services":
    "No se pudieron obtener los servicios favoritos",
  "error.could-not-get-transactions":
    "No se pudieron obtener las transacciones",
  "error.could-not-get-transactions-report":
    "No se pudo obtener el reporte de transacciones",
  "error.could-not-get-transaction-key":
    "No se pudo obtener el key de la transacción",
  "error.could-not-download-pdf": "No se pudo descargar el pdf",
  "error.could-not-get-colaborators-activity":
    "No se pudieron obtener las actividades de los colaboradores",
  "error.could-not-get-users-state":
    "No se pudieron obtener los estados de los usuarios",
  "error.could-not-get-colaborator-summary":
    "No se pudo obtener el resumen del colaborador",
  "error.could-not-get-history-of-colaborator":
    "No se pudo obtener el histórico del colaborador",
  "error.could-not-generate-user-activity-report":
    "No se pudo generar el reporte de actividad de usuario",
  "error.could-not-get-credits-state":
    "No se pudo obtener los estados de crédito",
  "error.could-not-get-cancelled-operations":
    "No se pudieron obtener las operaciones canceladas",
  "error.could-not-get-credits-by-age":
    "No se pudieron obtener los créditos por edad",
  "error.could-not-get-credits-by-city":
    "No se pudieron obtener los créditos por ciudad",
  "error.color-format":
    "Los siguientes formatos son soportados: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color().",
  "error.color-space-format":
    "Los siguientes formatos son soportados: srgb, display-p3, a98-rgb, prophoto-rgb, rec-2020.",
  "error.could-not-reset-no-changes": "No se pudo resetear, no hay cambios",
  "error.could-not-changes-to-apply": "No hay cambios para aplicar",
  "error.couldnt-bind-device": "No se pudo vincular el dispositivo",
  "error.file-size-exceeds-limit":
    "Los archivos superan el límite \n de tamaño total",
  "error.file-size-warning-body": "Podés subir hasta 4MB para enviar.",
  "error.could-not-get-banks": "No se pudieron obtener los bancos",
  "error.could-not-sync-banks": "No se pudieron sincronizar los bancos",
  "error.could-not-edit-bank": "No se pudo editar el banco",

  /* forms */
  "form.error.input-required": "Este campo es requerido",
  "form.date.type-error": "Debes ingresar una fecha",
  "form.optional-reason.label": "Ingresa un motivo (Opcional)",
  "form.reason.label": "Ingresa un motivo",
  "form.error.file-size": "El tamaño del archivo no debe sobrepasar 4MB",
  "form.required-reason.label": "Ingresa un motivo (Requerido)",
  "form.error.input-all-required": "Debes completar todos los campos",
  "form.error.incorrect-email": "Email incorrecto",
  "form.error.birthday-greater-than-admission-date":
    "La fecha de nacimiento debe ser menor a la de ingreso",
  "form.error.birthday-greater-than-current":
    "La fecha de nacimiento no puede ser posterior a la actual",
  "form.error.admission-greater-than-current":
    "La fecha de ingreso no puede ser posterior a la actual",
  "form.know-your-customer.text-filter": "Buscar por letra inicial del nombre:",
  "form.error.all-inputs-required": "Todos los campos son requeridos",
  "form.error.not-negative-to-all-inputs": "Ningún campo debe ser negativo",
  "form.error.input-invalid-value": "El valor ingresado es inválido",
  "form.error.input-invalid-format": "El formato ingresado es incorrecto",
  "form.error.input-initial-end-comma-error":
    "La lista no debe empezar o terminar con coma",
  "form.error.not-square-image": "El aspect ratio debe ser 1:1",
  "form.error.invalid-number-format": "El formato ingresado es incorrecto",
  "form.error.repeated-item": "El item seleccionado ya existe",
  "form.info.no-options-message": "No hay más opciones",
  "form.error.property-required": 'La propiedad "{property}" es requerida',
  "form.error.filter.required": "Debe completar el filtro",
  "form.error.filter.last_session.invalid":
    "El valor ingresado en el campo última sesión es inválida",
  "form.error.filter.session_count.invalid":
    "El valor ingresado en el campo cantidad de sesiones es inválida",
  "form.error.filter.first_session.invalid":
    "El valor ingresado en el campo primera sesión es inválida",
  "form.error.filter.language.invalid":
    "El valor ingresado en el campo lenguaje es inválida",
  "form.error.filter.country.invalid":
    "El valor ingresado en el campo país es inválida",
  "form.error.filter.app_version.invalid":
    "El valor ingresado en el campo versión de la app es inválida",
  "form.error.filter.latitude.invalid":
    "El valor ingresado en el campo latitud es inválida",
  "form.error.filter.longitude.invalid":
    "El valor ingresado en el campo longitud es inválida",
  "form.error.filter.radius.invalid":
    "El valor ingresado en el campo radio es inválida",
  "form.error.filter.tag.invalid":
    "El campo no debe quedar vacío o contener espacios al inicio",
  "form.error.image_upload": "No se ha podido subir la imagen",
  "form.error.activation_date":
    "La fecha de activación no puede ser anterior a la actual",
  "form.error.tickets.date":
    "La fecha desde no puede ser mayor a la fecha hasta",
  "form.error.tickets.document.length":
    "El número de documento debe contener al menos 6 digitos",
  "form.error.tickets.document.negative":
    "El número de documento no puede ser negativo",
  "form.error.tickets.not.adult": "El usuario debe ser mayor de edad",
  "form.error.invalid-horizontal-aspect-ratio": "El aspect ratio debe ser 16:9",
  "form.error.invalid-vertical-aspect-ratio": "El aspect ratio debe ser 9:16",
  "form.error.invalid-file-type": "El tipo de archivo no es válido",
  "form.error.from-date-required": "El campo Desde es requerido",
  "form.error.to-date-required": "El campo Hasta es requerido",
  "form.error.amount-greater-equal-zero":
    "El monto debe ser mayor o igual a cero",
  "form.error-fill-required": "Debes completar todos los campos requeridos",
  "form.error.file-not-existent": "El archivo no existe",
  "form.error.max-image-limit": "Se puede subir como máximo una imágen",
  "form.error.empty-url": "La url está vacía",
  "form.error.from-date-greater-than-to-date":
    "La fecha desde no puede ser posterior a la fecha hasta",
  "form.error.activation-date-less-than-finalization-date":
    "La fecha de finalización no puede ser inferior a la fecha de activación",
  "form.error.amount-to-less-than-amount-from":
    "El monto hasta no puede ser menor al monto desde",

  /* Disponibilidad de servicios */
  "disponibilidad-servicios.header": "Disponibilidad de servicios",
  "disponibilidad-servicios.filter": "Responsables",
  "disponibilidad-servicios.down-description":
    "¿Estás seguro que deseas interrumpir los servicios de Billetera Mango?",
  "disponibilidad-servicios.interruptions": "Interrupciones",
  "disponibilidad-servicios.downBy": "Interrumpido por: {firstName} {lastName}",
  "disponibilidad-servicios.service-state": "Servicios: {state}",
  "disponibilidad-servicios.upBy": "Reestablecido por: {firstName} {lastName}",
  "disponibilidad-servicios.up-description":
    "¿Estás seguro que deseas reestablecer los servicios de Billetera Mango?",
  "disponibilidad-servicios.reason-description":
    "Servicio restablecido. Indica el motivo para mantener un registro del restablecimiento de los servicios.",
  "disponibilidad-servicios.date-time-label": "Fecha y hora de inicio",
  "disponibilidad-servicios.responsible-label": "Responsable",
  "disponibilidad-servicios.edit-reason": "Editar motivo",
  "disponibilidad-servicios.alt-partial-interruption": "Interrupción parcial",
  "disponibilidad-servicios.service-status": "Estado de servicio",

  /* Influencer */
  "influencer.header": "Influencer",
  "influencer.modal.visor-title": "Prueba",
  "influencer.delete-modal-title": "Eliminar",
  "influencer.delete-modal-body":
    "¿Estás seguro que deseas eliminar el item seleccionado?",
  "influencer.form-error.not-greater-than-cero":
    "El monto debe ser mayor a cero",
  "influencer.form-error.invalid-url": "La url es inválida",
  "influencer.form-error.must-start-with-at":
    "Debe comenzar con @ y solo al inicio",
  "influencer.network": "Redes",
  "influencer.social-network": "Red social",

  /* 07. Wizard */
  "wizard.prev": "Anterior",
  "wizard.next": "Siguiente",

  /* Colaboradores */
  "colaboradores.header": "Lista de Colaboradores",
  "colaboradores.create-modal": "Agregar Colaborador",
  "colaboradores.edit-modal": "Editar Colaborador",
  "colaboradores.delete-modal-title": "Eliminar Colaborador",
  "colaboradores.delete-modal-body":
    "¿Estás seguro que deseas eliminar el colaborador seleccionado?",
  "colaboradores.reactivate-modal-title": "Reactivar Colaborador",
  "colaboradores.reactivate-modal-body":
    "¿Estás seguro que deseas reactivar el colaborador seleccionado?",
  "roles.header": "Lista de Roles",
  "roles.create-modal": "Agregar Rol",
  "roles.edit-modal": "Editar Rol",
  "roles.view-modal": "Rol Eliminado",
  "roles.delete-modal-title": "Eliminar Rol",
  "roles.delete-modal-body":
    "¿Estás seguro que deseas eliminar el rol seleccionado?",
  "roles.form-modal.no-permission-selected":
    "El rol debe tener al menos un permiso",
  "roles.select-all-permissions": "Seleccionar todos los permisos",
  "roles.filter-roles-by-permissions": "Filtrar roles por permisos",

  /* Tickets */
  "tickets-details.header": `TICKET #{ticketId} - {user}`,
  "tickets.header": "Administrar tickets",
  "tickets.ticket-description": "Descripción del ticket",
  "tickets.assing-collaborator": "Asignar colaborador",
  "tickets.without-phone-number": "Sin número de teléfono por cancelación",
  "tickets.table-title": "Lista de tickets",
  "tickets.notification.id-copied": "ID copiado",
  "tickets.modal-body":
    "Este usuario ya posee un ticket abierto. No podés crear uno nuevo hasta cerrar el existente.",
  "ticket.filters.collaborators": "Colaboradores",
  "ticket-details.tab.details": "DETALLES",
  "ticket-details.devices.modal-title": "Liberar dispositivo",
  "ticket-details.devices.modal-caption":
    "¿Estas intentando liberar el dispositivo?. De forma resumida ingresa el motivo de la liberación. Esto ayudará en revisiones futuras.",
  "ticket-details.devices.modal.unblock-title": "Desbloquear dispositivo",
  "ticket-details.devices.modal.unblock-caption":
    "Ingresa el motivo del desbloqueo del dispositivo. \nEsto ayudará en revisiones futuras.",
  "ticket-details.devices.modal.block-title": "Bloquear dispositivo",
  "ticket-details.devices.modal.block-caption":
    "Ingresa el motivo del bloqueo del dispositivo. \nEsto ayudará en revisiones futuras.",
  "ticket-details.devices.trust-title": "Asignar confianza",
  "ticket-details.devices.modal.trust-title": "Dispositivo de confianza",
  "ticket-details.devices.modal.trust-caption":
    "Ingresa el motivo de la asignación de confianza. Esto ayudará en revisiones futuras.",
  "ticket-details.devices.modal.bind-title": "Vincular dispositivo",
  "ticket-details.devices.modal.bind-caption":
    "¿Estas intentando vincular el dispositivo? De forma resumida ingresa el motivo. Esto ayudará en\n revisiones futuras.",
  "ticket-details.devices.modal.device-in-use": "Dispositivo en uso",
  "ticket-details.devices.modal.device-in-use-caption-1":
    "Este dispositivo está en uso en la cuenta de",
  "ticket-details.devices.modal.device-in-use-caption-2":
    ". Si continúas, el cambio se hará de forma automática.",
  "ticket-details.tab.disabled-label":
    "Sección desabilitada debido a que el ticket se encuentra cerrado",
  "ticket-details.tab.transactions": "HISTORIAL DE TRANSACCIONES",
  "ticket-details.view-transactions-detail": "Detalle de transacción",
  "ticket-details.view-detail": "Datos adicionales",
  "ticket-details.tab.errors": "REGISTRO DE ERRORES",
  "ticket-details.tab.user-settings": "CONFIGURACIONES DEL USUARIO",
  "ticket-details.tab.comment-change": "cambió a",
  "ticket-details.tab.data-and-photos": "GESTIÓN DE DATOS Y FOTOS DE CI",
  "ticket-details.user-settings.title": "Configuraciones de usuario",
  "ticket-details.data-and-photos.title": "Gestión de datos y fotos de CI",
  "ticket-details.verification.fc": "Foto frontal de cédula",
  "ticket-details.verification.dc": "Foto del dorso de cédula",
  "ticket-details.verification.selfie": "Foto selfie con cédula",
  "ticket-details.data-and-photos.description":
    "Los cambios se aplicaran directamente en la cuenta del usuario mango ",
  "ticket-details.data-and-photos.history": "Historial de carga de fotos",
  "ticket-details.user-settings.description":
    "Los cambios se aplicaran directamente en la cuenta del usuario mango",
  "ticket-details.details.comments": "Comentarios",
  "ticket-details.details.sending": "Enviando...",
  "ticket-details.details.error": "No pudimos enviar este comentario",
  "ticket-details.details.ticket": "Datos del ticket",
  "ticket-details.details.user": "Datos de usuario",
  "ticket-details.hide-balance": "Gs. ******",
  "ticket-details.transactions.table-title": "Historial de Transacciones",
  "ticket-details.transactions.filters": "Tipo",
  "ticket-details.transactions.filter-all": "Todo los tipos",
  "ticket-details.errors.table-title": "Historial de Errores",
  "ticket-details.devices.table-title": "Dispositivos registrados",
  "ticket-details.bank-statement.table-title": "Extracto de cuenta",
  "ticket-details.errors.filters": "URL",
  "ticket-details.errors.filters-all": "Todos",
  "ticket-details.money-transfer": "Envío de Dinero",
  "ticket-details.withdrawal": "Extracción",
  "ticket-details.payment-with-qr": "Pago con QR",
  "ticket-details.order-payment": "Pago de Pedido",
  "ticket-details.services-payment": "Pago de Servicios",
  "ticket-details.vaquita-payment": "Pago de Vaquita",
  "ticket-details.recharge": "Recarga",
  "ticket-details.refund": "Reintegro",
  "ticket-details.ci-edition-popover":
    "Este campo no se puede editar porque el usuario ya posee una cuenta en TU FINANCIERA",
  "ticket-create.header": "Crear Ticket",
  "ticket-create.number-dont-match":
    "El número no pertenece al usuario ingresado",
  "ticket-create.number-format.error":
    "El número no tiene el formato correcto. Pruebe con el formato 09XX XXX XXX",
  "ticket-create.continue-creating-tickets": "Continuar creando tickets",
  "ticket-create.incident-category": "Categoría del incidente",
  "ticket-create.incident-details": "Detalles del incidente",
  "creat-ticket.user-card.title": "Datos del usuario",
  "creat-ticket.incident-card.title": "Datos del incidente",
  "creat-ticket.user-card.button": "Generar preguntas",
  "creat-ticket.security-questions.title": "Preguntas de seguridad",
  "ticket.status.pending": "PENDIENTE",
  "ticket.status.resolved": "Resuelto",
  "ticket.status.expired": "Expirado",
  "ticket.status.no resolution": "Sin resolución",
  "ticket.status.tooltip":
    "Debes tener al menos un comentario antes de cerrar el ticket",

  "select.default.channel": "Canal Preferido Automático",
  "select.default.collaborators": "Todos los colaboradores",
  "ticket.user-details.bank-account-type": "Tipo de cuenta",
  "ticket.user-details.balance": "Saldo",
  "ticket.user-details.calculation-date": "Cancelación de cuenta",
  "ticket.user-details.user-id": "ID",
  "ticket.user-details.user-no-calculation-date": "Sin fecha",
  "ticket.user-details.user-no-balance-available": "No disponible",
  "tickets.redirect-to-credit-line": "Ir a línea de crédito",
  "tickets.navigation-menu-credits": "Créditos",
  "tickets.details.upload-file-to-comments-title": "Subir a comentarios",
  "tickets-details.comment-ticket-asignation": "El ticket fue asignado a ",
  "tickets-details.comment-ticket-went-out-status":
    "El ticket pasó del estado ",

  /* Extension de cuentas */
  "account-extension.sworn-declaration": "DECLARACIÓN JURADA",
  "account-extension.verification.next-file": "Siguiente",
  "account-extension.attached-document": "DOCUMENTO ADJUNTADO",
  "account-extension.attach-document": "ADJUNTAR COMPROBANTE DE INGRESO",
  "account-extension.extension-data.title":
    "Datos de extensión, declaración jurada",
  "account-extension.attatchment.number": "Adjunto Nº {number}",
  "account-extension.attatchment.ips": "Aporta IPS",
  "account-extension.attachment.description":
    "Asegúrate que el usuario cumpla con todos los requisitos para tener una cuenta básica extendida",
  "account-extension.request-status": "Estado de la solicitud: ",
  "account-extension.file-last-upload": "Cargado el {date} ",
  "account-extension.get-files.error":
    "Se ha producido un error al obtener el archivo",
  "account-extension.lock-pdf.error":
    "No se puede abrir el archivo \n porque posee contraseña",
  "account-extension-observation-label": "Observación",
  "account-extension.error-getting-extensions-requests":
    "No se pudieron obtener los datos de la solicitud de extensión",
  "account-extension.error-getting-comboboxes":
    "No se pudieron obtener las opciones de los comboboxes",
  "account-extension.error-getting-extensions":
    "No se pudieron obtener las extensiones",

  /* Know Your Customer (KYC) */
  "kyc.header": "Verificación de Identidad",
  "kyc-dashboard.rejected-accounts": "Cuentas rechazadas",
  "kyc.rejecting-permanently": "Rechazando \n permanentemente...",
  "kyc-dashboard.queued": "encoladas",
  "kyc-dashboard.queued-singular": "encolada",
  "kyc-dashboard.temporarily_rejected-accounts": "Rechazo temporal",
  "kyc-dashboard.active-accounts": "Cuentas aprobadas",
  "kyc-dashboard.pending-accounts": "Cuentas pendientes",
  "kyc-dashboard.restricted_access-accounts": "Cuentas restringidas",
  "kyc-dashboard.compliance-accounts": "Derivado a Cumplimiento",
  "kyc-dashboard.error-getting-dashboard": "No se pudo obtener el dashboard",
  "kyc.restricting-access": "Restringiendo \n acceso...",
  "kyc.allowing-access": "Permitiendo \n acceso...",
  "kyc-header-account-extension": "Solicitud de extensión",
  "kyc.verify-link": "Verificar",
  "kyc.restrict-reason.description":
    "¿Por qué estás restringiendo este usuario?. \n De forma resumida ingresa un motivo de restricción. \n Esto ayudará en revisiones futuras.",
  "kyc.allow-reason.description":
    "¿Por qué estás permitiendo el acceso a este usuario?. \n De forma resumida ingresa un motivo de quitar la restricción. Esto ayudará en revisiones futuras.",
  "kyc.reject-reason.description":
    "¿Por qué estás rechazando este usuario?. \n De forma resumida ingresa un motivo de rechazo. Esto ayudará en revisiones futuras.",
  "kyc.refer-to-compliance.description":
    "¿Por qué estás derivando este caso? \n De forma resumida ingresa un motivo de la \n derivación. \n Esto ayudará en revisiones futuras.",
  "kyc.verify.confirmation-modal.description":
    "Se va a crear un ticket por un problema de registro de este usuario. Deseas continuar?",
  "kyc.verify.remove-reason.description":
    "¿Por qué estás quitando este caso de Cumplimiento?\n De forma resumida ingresa el motivo. Esto ayudará\n en revisiones futuras.",
  "kyc.verify.unlist-description":
    "Se quitará de Derivado a Cumplimiento al usuario.",
  "kyc.verify.remove-reason.title": "Quitar de Cumplimiento",
  "kyc.verify.create-ticket.incident-category": "Problema de registro",
  "kyc.verify.create-ticket.description": "Usuario con problema en el registro",
  "kyc.list-type": "Tipo de lista",
  "kyc-document-nro": "Documento Nro",
  "kyc-was-received-at": "Se recibió el",
  "kyc-verification-type": "Tipo de verificación",
  "kyc-identity-verfication-state": "Estado de verificación de identidad",
  "kyc-bank-account-number": "Número de caja",
  "verify-kyc.header": "{fullName} [{nickname}]",
  "verify-kyc.header.no-user": "Sin usuario",
  "verify-kyc.without-change-warning": "No se ha realizado ningún cambio",
  "verify-kyc.subheader-approved": "Cuentas de usuario aprobados",
  "verify-kyc.subheader-pending": "Cuentas pendientes de aprobación",
  "verify-kyc.ci.title": "Fotos de cédula",
  "verify-kyc.get-images.error":
    "Se ha producido un error al obtener la imagen",
  "verify-kyc-observation-label": "OBSERVACIÓN",
  "verify-kyc.user-photo.no-observation": "No tiene observaciones",
  "verifyc-kyc.user-photo.observation-validation":
    "La observación es requerida",

  "verify-kyc.user.title": "Datos ingresados por el usuario",
  "verify-kyc.empty.description":
    "No quedan más usuarios \n pendientes de validación",
  "verify-kyc.verified-user.description":
    "El usuario ya ha sido verificado. \n ¿Deseas ver el siguiente usuario?",
  "verify-kyc.verification-history.second-tab": "Galería",
  "verify-kyc.verification-history.buttonLink": "Ver en grande",
  "verify-kyc.verification-history.header": "Historial de verificación",
  "verify-kyc.user-account-state.title": "Estados de la cuenta",
  "verify-kyc.user-account.rejected": "Rechazado",
  "verify-kyc.user-account.temporarily-rejected": "Rechazado temporalmente",
  "verify-kyc.user-account.temporal-reject": "Rechazo temporal",
  "verify-kyc.user-account.restricted": "Acceso restringido",
  "verify-kyc.user-account.blocked": "Bloqueado",
  "verify-kyc.user-photo.rejected": "Rechazado",
  "verify-kyc.user-photo.approved": "Aprobado",
  "verify-kyc.user-photo.not-verified": "No verificado",
  "verify-kyc.verifing-by": "Verificando por",
  "verify-kyc.user-photo.unreviewed": "Sin verificar",
  "verify-kyc.user-form.create-ticket": "Crear ticket",
  "verify-kyc.user-form.creating-ticket": "Creando \n ticket...",
  "verify-kyc.user-form.tab1": "Datos del usuario",
  "verify-kyc.user-form.tab2": "Datos adicionales",
  "verify-kyc.user-photos.title": "Gestión de fotos",
  "verify-kyc.validate.title": "Verificar datos de usuario",

  "verify-kyc.verification.frontPhoto": "Foto frontal de la cédula",
  "verify-kyc.verification.backPhoto": "Foto trasera de la cédula",
  "verify-kyc.verification.selfie": "Foto selfie sosteniendo la cédula",
  "verify-kyc.verification.colaborator": "Colaborador:",
  "verify-kyc.verification.state": "Estado de la cuenta:",
  "verify-kyc.verification.state-literal": "Estado de verificación",
  "verify-kyc.verification.next-photo": "Siguiente foto",
  "verify-kyc.verification.selfie.description":
    "EL rostro y la cédula deben visualizarse claramente y los rostros deben coincidir.",
  "verify-kyc.verification.backPhoto.description":
    "Asegúrate de que los datos se vean correctamente en la foto.",
  "verify-kyc.verification.frontPhoto.description":
    "Foto legible, bien iluminada.",
  "verify-kyc.verification.reason": "Motivo de rechazo:",
  "verify-kyc.verification.createdAt": "Fecha:",
  "verify-kyc.history.title":
    "Historial de actualizaciones de estado del usuario",
  "verify-kyc.compliance": "Derivado a Cumplimiento",
  "verify-kyc.refer-to-compliance": "Derivar a Cumplimiento",
  "verify-kyc.refering-to-compliance": "Derivando a Cumplimiento...",
  "verify-kyc.refer-to-compliance-tooltip": "Derivado a Cumplimiento",
  "verify-kyc.permanent-reject": "Rechazo permanente",
  "verify-kyc.permanently-rejecting": "Rechazando permanentemente",
  "verify-kyc.restrict-access": "Restringir acceso",
  "verify-kyc.restricting-access": "Restringiendo acceso",
  "verify-kyc.confidence-level-title": "Similitud selfie con cédula",
  "verify-kyc.not-black-list-reason-header":
    "Si el usuario NO figura en la \n lista negativa, ingrese el motivo",
  "verify-kyc.notificate-user-by-whatsapp":
    "Notificación al usuario vía WhatsApp",
  "verify-kyc.add-reason": "Agregar motivo",
  "verify-kyc.select-placeholder": "Seleccionar...",
  "verify-kyc.notification-destiny": "Destino:",
  "verify-kyc.user-restricted-info": "Haz restringido el acceso al usuario.",
  "verify-kyc.remove-from-compliance": "Quitar de Cumplimiento",
  "verify-kyc.removing-from-compliance": "Quitando de Cumplimiento...",
  "verify-kyc.other-actions": "Otras acciones",
  "verify-kyc.verifying-user-black-list":
    "Verificando si el usuario aparece en una lista negativa...",
  "verify-kyc.user-derived-to-compliance":
    "Haz derivado a Cumplimiento al usuario.",
  "verify-kyc.security-alert": "Alerta de seguridad",
  "verify-kyc.security-alert-description":
    "Coincidencias del usuario en Lista \n Negativa. Verifica si el usuario figura en la \n lista para continuar.",
  "verify-kyc.error-getting-users-by-state":
    "No se pudo obtener la cantidad de usuarios por estado",
  "verify-kyc.mock-notification-destiny": "Destino: +595 981000000",

  /* Promotions */
  "promotions.header": "Promociones",
  "promotions.current-promotion": "Promociones vigentes",
  "promotions.first-step.name-label": "Nombre de la promoción",
  "promotions.subtype-label": "Función",
  "promotions.first-step.participants-shops": "Comercios participantes",
  "promotions.first-step.participants-brands": "¿Que servicios participan?",
  "promotions.first-step.select-all-shops": "Seleccionar todos los comercios",
  "promotions.first-step.select-all-services":
    "Seleccionar todas las entidades",
  "promotions.second-step.date-from-label": "Periodo de inicio",
  "promotions.second-step.time-from-label": "Hora de inicio",
  "promotions.second-step.priority-error":
    "La prioridad debe ser un número mayor o igual a 1 y menor o igual a 20",
  "promotions.start-date-is-before-current":
    "La fecha de inicio no puede ser anterior a la actual",
  "promotions.time-start-is-before-current":
    "La hora de inicio no puede ser anterior a la actual",
  "promotions.modal-search-input-placeholder": "DD/MM/AA",
  "promotions.manage-dates-modal-title": "Administrar fechas",
  "promotions.confirmation-modal.title": "Aviso importante",
  "promotions.confirmation-modal.description":
    "Estas intentando editar una fecha establecida. Esto reiniciará el calendario de esta promoción.",
  "promotions.first-step.confirmation-modal.description":
    "Si cambias la función, se perderán los cambios realizados previamente.",
  "promotions.second-step.date-time-to-label": "Finalización",
  "promotions.fourth-step.type-label": "Tipo de promoción",
  "promotions.fourth-step.unlimited": "Sin límites de importe",
  "promotions.fourth-step.min-amount-label": "Importe mínimo",
  "promotions.fourth-step.perDay-not-multiple":
    "El importe máximo en descuento por día debe ser múltiplo del monto fijo",
  "promotions.fourth-step.perWeek-not-multiple":
    "El importe máximo en descuento por semana debe ser múltiplo del monto fijo",
  "promotions.fourth-step.perMonth-not-multiple":
    "El importe máximo en descuento por mes debe ser múltiplo del monto fijo",
  "promotions.fourth-step.perPromotion-not-multiple":
    "El importe máximo en descuento por promoción debe ser múltiplo del monto fijo",
  "promotions.fourth-step.perDay-smaller":
    "El importe máximo en descuento por día debe ser mayor o igual al monto fijo",
  "promotions.fourth-step.perWeek-smaller":
    "El importe máximo en descuento por semana debe ser mayor o igual al monto fijo",
  "promotions.fourth-step.perMonth-smaller":
    "El importe máximo en descuento por mes debe ser mayor o igual al monto fijo",
  "promotions.fourth-step.perPromotion-smaller":
    "El importe máximo en descuento por promoción debe ser mayor o igual al monto fijo",
  "promotions.fourth-step.perDay-error":
    "El importe máximo en descuento por día no puede ser menor que el importe máximo",
  "promotions.fourth-step.perWeek-error":
    "El importe máximo en descuento por semana no puede ser menor que el importe máximo",
  "promotions.fourth-step.perMonth-error":
    "El importe máximo en descuento por mes no puede ser menor que el importe máximo",
  "promotions.fourth-step.perPromotion-error":
    "El importe máximo en descuento por promoción no puede ser menor que el importe máximo",
  "promotions.fourth-step.max-amount-error":
    "El importe máximo no puede ser menor al importe mínimo",
  "promotions.fourth-step.amount-error": "El monto fijo debe ser mayor a cero",
  "promotions.fourth-step.percentage-label": "Definir porcentaje",
  "promotions.fourth-step.max-amount-label": "Importe máximo",
  "promotions.fourth-step.amount-label": "Monto fijo",
  "promotions.fifth-step.create-promo-title": "Promoción creada",
  "promotions.fifth-step.edit-promo-title": "Promoción editada",
  "promotions.fifth-step.active": "ACTIVO",
  "promotions.fifth-step.inactive": "INACTIVO",
  "promotions.fifth-step.promo-name-label": "Nombre:",
  "promotions.fifth-step.percentage-label": "Porcentaje de descuento:",
  "promotions.fifth-step.from-label": "Inicia:",
  "promotions.fifth-step.to-label": "Finaliza:",
  "promotions.fifth-step.participants-label": "Comercios participantes:",
  "promotions.fifth-step.participants-all": "Todos",
  "promotions.fifth-step.amount-label": "Monto fijo:",
  "promotions.fourth-step.max-amount-off-label": "Importe máximo en descuento",
  "promotions.fourth-step.max-amount-per-day": "Por día",
  "promotions.fourth-step.max-amount-per-week": "Por semana",
  "promotions.fourth-step.max-amount-per-month": "Por mes",
  "promotions.fourth-step.max-amount-per-promo": "Por promoción",
  "promotions.fourth-step.state-label": "Estado de promoción",
  "promotions.third-step.title": "¿Quiénes participan en la promoción?",
  "promotions.third-step.lottie-description":
    "Configure quienes participan en la promoción",
  "promotions.second-step.open-modal-error":
    "Primero debes completar los campos requeridos para poder administrar las fechas",
  "promotions.second-step.days-of-week-error":
    "Debe seleccionar al menos un día del calendario",
  "promotions.second-step.no-date-selected-error":
    "Se debe seleccionar al menos una fecha",
  "promotions.second-step.invalid-time-end-error":
    "La hora de finalización del ultimo dia no debe ser mayor a la hora seleccionada",
  "promotions.second-step.time-end-error":
    "La hora de finalización no puede ser menor o igual a la hora de inicio",
  "promotions.second-step.time-start-error":
    "La hora de inicio no puede ser mayor o igual a la hora de finalización",
  "promotions.second-step.invalid-time-start-error":
    "La hora de inicio del primer dia no debe ser menor a la hora seleccionada",
  "promotions.second-step.empty-field-error":
    "Ningún campo seleccionado puede estar vacío. Por favor verifique",
  "promotions.second-step.checkbox-deselect-all": "Deseleccionar todo",
  "promotions.second-step.date-error":
    "La fecha de finalización no puede ser menor a la fecha de inicio",
  "promotions.second-step.time-error":
    "La hora de finalización no puede ser menor o igual a la hora de inicio",
  "promotions.second-step.checkboxes-title": "Administrar días",
  "promotions.second-step.manage-date-button": "Administrar fechas",
  "promotions.second-step.priority-label": "Definir prioridad",
  "promotions.subtype-filter.label": "Filtrar por función",
  "promotions.type-filter.label": "Filtrar por tipo",
  "promotions.type-filter.label-variant": "Filtrar por tipo:",
  "promotions.state-filter.label": "Filtrar por estado",
  "promotions.state-filter.label-variant": "Filtrar por estado:",
  "promotions.step-0.card-title": "Datos de la promoción",
  "promotions.step-1.card-title": "Periodo y parámetros",
  "promotions.step-2.card-title": "Delimitar por usuario",
  "promotions.step-3.card-title": "Tipo de promoción",
  "promotions.step-4.create-card-title": "Creaste una promoción",
  "promotions.step-4.edit-card-title": "Editaste una promoción",
  "promotions.first-step.search-tag-label": "Buscar por etiquetas",
  "promotions.first-step.search-entity-label": "Buscar por entidades",
  "promotions.first-step.search-category-label": "Buscar por categorías",
  "promotions.first-step.add-shops-label": "Agregar comercios",
  "promotions.first-step.add-entities-label": "Agregar entidades",
  "promotions.second-step.lottie-label":
    "Configure periodo de tiempo y límites de horario",
  "promotions.third-step.lottie-label":
    "Configure el estilo y límites de la promoción",
  "promotions.first-step.lottie-label":
    "Ingrese los datos de la nueva promoción",
  "create-promotion.header": "Alta de promoción",
  "edit-promotion.header": "Edición de promoción",
  "promotions.delete-modal-body":
    "¿Estás seguro que deseas remover esta promoción?",
  "promotions.delete-modal-title": "Eliminar promoción",
  "promotions.notification.no-selected-shops":
    "No puedes continuar sin agregar al menos un comercio",
  "promotions.notification.no-selected-services":
    "No puedes continuar sin agregar al menos una entidad",
  "promotions.service-payment": "Pago de servicios",
  "promotions.service-payment-2": "Pago de servicio",
  "promotions.all-mango-users": "Todos los usuarios mango",
  "promotions.delimit-by-@": "Delimitar por @",
  "promotions.start-and-end-date": "Fecha de inicio y fin",
  "promotions.first-step": "Primer paso",
  "promotions.next-step": "Siguiente paso",
  "promotions.finished-step": "Paso finalizado",
  "promotions.last-step": "Último paso",
  "promotions.last-selected-step": "Último paso seleccionado",

  /* tags */
  "tags.header": "Etiquetas",
  "tags.create-modal": "Alta de etiqueta",
  "tags.edit-modal": "Editar etiqueta",
  "tags.delete-modal-title": "Eliminar etiqueta",
  "tags.delete-modal-body":
    "¿Estás seguro que deseas eliminar la etiqueta seleccionada?",
  "tags.tag-color": "Color de etiqueta",

  /* commerce categories */
  "commerce-categories.header": "Categorías",
  "commerce-categories.create-modal": "Alta de categoría",
  "commerce-filter-by-default": "Filtrar por...",
  "commerce-categories.edit-modal": "Editar categoría",
  "commerce-categories.delete-modal-title": "Eliminar categoría",
  "commerce-categories.delete-modal-body":
    "¿Estás seguro que deseas eliminar la categoría seleccionada?",
  "commerce-list.delete-modal-body":
    "¿Estás seguro que deseas remover este comercio?",
  "commerce-list.delete-modal-title": "Aviso Importante",
  "commerce-view-category.add-commerce-modal-title": "Agregar comercios",

  /* shops */
  "shops.header": "Comercios",
  "shops.create-modal": "Alta de comercio",
  "shops.edit-modal": "Editar comercio",
  "shops.form-error.not-square-logo": "La imagen debe ser cuadrada",
  "shops.shops-affiliated": "Comercios adheridos",
  "shops.bancard-qr-identifier": "Identificador BANCARD QR",

  /* Marketing */
  "push-notifications.header": "Notificaciones Push",
  "push-notifications.send-push": "Crear Notificación Push",
  "push-notifications.button-caption":
    "Por favor verifica los datos del PUSH con una versión de prueba. Este paso es necesario para crear el push.",
  "push-notifications.checkbox-label": "He verificado la versión de prueba",
  "push-notifications.delete-modal-title": "Eliminar notificación push",
  "push-notifications.delete-modal-body":
    "¿Estás seguro de que quieres eliminar la notificación push seleccionada?",
  "push-notifications.view-notifications-details": "Detalle de Notificación",
  "push-notifications-duplicate": "Duplicar",
  "push-notifications-device-type": "Tipo de dispositivo",
  "push-notifications-deliveryTime-now": "Ahora",
  "push-notifications-default-title": "Default Title",
  "push-notifications-default-message": "Default Message",
  "push-notifications.enter-participating-users":
    "Ingresa los usuarios que participan",
  "push-notifications.message-title": "Título del mensaje",
  "push-notifications.notification-type": "Tipo de Notificación",
  "push-notifications.screen-redirection": "Redirección a pantalla",
  "push-notifications.distribution-type": "Tipo de distribución",
  "push-notifications.schedule-delivery-time": "Programar hora de envío",
  "banners.header": "Banners",
  "banners.upload-image.title": "Adjuntar imagen",
  "banners.create-modal": "Crear Banner",
  "banners.edit-modal": "Editar Banner",
  "banners.order-banner": "Ordenar banners",
  "banners.order-update": "ACTUALIZAR ORDEN",
  "banners.tab-events": "Novedades/Promociones",
  "banners.tab-extension": "Extensión de cuenta",
  "banners-tab-my-credit": "Mi Crédito",
  "banners.modal.conditions.label": "Condiciones",
  "banners.modal.switch.label": "Activar reglas y condiciones",
  "banners.delete-modal-body": "¿Estás seguro que deseas eliminar este banner?",
  "banners.form-error.horizontal-aspect-ratio":
    "El aspect ratio debe ser {aspectRatio}",
  "banners.form-error.vertical-aspect-ratio": "El aspect ratio debe ser 9:16",
  "banners.type-filter": "Tipo de banner",
  "banners.add-image-with-aspect-ratio":
    "Adjuntar imagen, BANNER HOME ( {aspectRatio} )",
  "banners.screen-redirection": "Redirección a pantalla",
  "banners.add-modal-image": "Adjuntar imagen, MODAL ( 9:16 )",
  "segments.header": "Segmentos",
  "segments.segment-name": "Nombre del segmento",
  "segments.filter.location.map-modal-title": "Asignar punto GPS",
  "segments.filter.location.radius": "Radio",
  "segments.filter.location.latitude": "Latitud",
  "segments.filter.location.longitude": "Longitud",
  "segments.filter.placeholder.radius": "Radio",
  "segments.filter.placeholder.latitude": "Latitud",
  "segments.filter.placeholder.longitude": "Longitud",
  "segments.create-modal": "Crear Segmento",
  "segments.select-segment": "Seleccionar segmento",
  "segments.view-modal": "Detalle del segmento",
  "segments.delete-modal-title": "Eliminar Segmento",
  "segments.delete-modal-body":
    "¿Estás seguro que deseas eliminar el segmento seleccionado?",

  /* Integraciones */
  "integrations.header": "Integraciones",
  "integrations.add-integration": "Agregar integración",
  "integrations.notifications.header": "Notificaciones",
  "integrations.view-details": "Ver detalles",
  "configurations.header": "Configuraciones",
  "configurations.create-modal": "Crear Configuración",
  "configurations.edit-modal": "Editar Configuración",
  "configurations.list-header": "Lista de complementos",
  "configurations.show-in-splash": "Se devuelve en el Splash",
  "conectivity.header": "Estado de integraciones",
  "conectivity.popup.no-inactivity":
    "No se registró tiempo de inactividad en este día",
  "conectivity.popup.parcial-inactivity": "Interrupción parcial",
  "conectivity.popup.important-inactivity": "Caída importante",
  "conectivity.popup.inactivity-detail": "Detalle de inactividad",
  "conectivity.popup.last-days": "Últimos {days} días",
  "conectivity.popup.last-day": "Último {day} día",
  "conectivity.popup.percentage": "{percentage} de conectividad",
  "connectivity.state-label": "Estado de conectividad",
  "infonet.header": "INFONET",
  "infonet.order.preview": "Vista previa",
  "infonet.services": "Categorías de servicios",
  "infonet.configuration": "Configuraciones de INFONET",
  "infonet.configuration.no-data-to-show": "No hay {title} para mostrar",
  "infonet.categories.create": "Agregar categoría",
  "infonet.categories.list-title": "Lista de entidades",
  "infonet.categories.switch.activate": "Desactivado",
  "infonet.categories.switch.deactivate": "Activado",
  "infonet.categories.not-editable":
    "No podés agregar o quitar integraciones en esta categoría",
  "infonet.categories.edit": "Editar Categoría",
  "infonet.integrations.edit": "Editar Integración",
  "infonet.delete-modal-title": "Eliminar categoría",
  "infonet.delete-modal-body":
    "¿Estás seguro que deseas eliminar la categoría seleccionada?",
  "infonet.bancard-state": "Estado Bancard",
  "notifications.configuration.minutes": "Minutos",
  "notifications.configuration.inputText":
    "Reenviar notificaciones de los últimos",
  "configurations.cancel-modal.text": "¿Deseas cerrar sin guardar los cambios?",
  "configurations.cancel.modal.modalTitle": "Advertencia",
  "integrations.bank-entities.modal.title": "Editar entidad",

  /* Reportes */
  "reports.header": "Reportes de transacciones",
  "reports.identity-verification": "Reporte de verificación",
  "reports.collaborator-history.view-details": "ver detalles",
  "reports.download-pdf": "Descargar reportes",
  "reports.download-email-sent":
    "Se enviará un correo para la descarga del archivo",
  "reports.download-report": "Descargar reporte",
  "reports.download-report-pdf": "Descargar reporte.pdf",
  "reports.endpoints.header": "Endpoints",
  "reports.endpoints.table-title": "Tiempo de respuesta Endpoint",
  "reports.endpoints.module-name": "Nombre del módulo",
  "reports.endpoints.ascendant": "Ascendente",
  "reports.endpoints.descendant": "Descendente",
  "reports.endpoints.response-time": "Tiempo de respuesta",
  "reports.colaborators-tracking-title": "Actividad de colaboradores",
  "reports.verified-users-legend-label": "Usuarios verificados",
  "reports.collaborator-graph.h-filter": "1H",
  "reports.collaborator-graph.s-filter": "1S",
  "reports.collaborator-graph.d-filter": "1D",
  "reports.collaborator-graph.w-filter": "7D",
  "reports.collaborator-graph.m-filter": "1M",
  "reports.collaborator-graph.y-filter": "1Y",
  "reports.verification-graph.label-registers": "Registros",
  "reports.verification-graph.label-pendings": "Pendientes",
  "reports.verification-graph.title": "Gráfico de reporte",
  "reports.loan-growth-history-graph.h-filter": "H",
  "reports.loan-growth-history-graph.d-filter": "D",
  "reports.loan-growth-history-graph.w-filter": "S",
  "reports.loan-growth-history-graph.m-filter": "M",
  "reports.loan-growth-history-graph.y-filter": "A",
  "reports.loan-growth-history-graph.h-tooltip": "Por hora",
  "reports.loan-growth-history-graph.d-tooltip": "Por día",
  "reports.loan-growth-history-graph.w-tooltip": "Por semana",
  "reports.loan-growth-history-graph.m-tooltip": "Por mes",
  "reports.loan-growth-history-graph.y-tooltip": "Por año",
  "reports.verifications-history.title": "Historial de verificaciones",
  "reports.verification.average-response": "Promedio de respuesta",
  "reports.verification.verfication-state": "Estado de verificación",
  "reports.registered-users-legend-label": "Usuarios registrados",
  "reports.colaborators-response-avg": "Promedio de respuesta:",
  "reports.users.header": "Reporte de usuarios",
  "reports.transactions-chart.header": "Total de transacciones",
  "reports.transactions.download-csv-label": "Descargar formato csv",
  "reports.transactions.download-file": "Descargar archivo",
  "reports.transactions-chart.sub-header": "Movimientos de Mango",
  "reports.transactions.tufi-loan": "Préstamo Tufi",
  "reports.transactions.qr-sales": "Ventas QR",
  "reports.transactions.credit-usage": "Uso de crédito",
  "reports.transactions.credit-payment": "Pago de crédito",
  "reports.users.active.title": "Usuarios activos",
  "reports.users.pending_validation.title": "Usuarios pendientes",
  "reports.users.users-state-label": "Estados de usuarios",
  "reports.users.rejected.title": "Usuarios rechazados",
  "reports.users-chart.new.header": "Nuevos usuarios",
  "reports.users-chart.transfer.header": "Transferencias por usuarios",
  "reports.users-chart.vaquita.header": "Vaquitas por usuarios",
  "reports.users-chart.extraction.header": "Extracciones por usuarios",
  "reports.users-chart.service.header": "Pago de servicios por usuarios",
  "reports.users-chart.new.sub-header": "Registro de nuevos usuarios",
  "reports.users-chart.transfer.sub-header":
    "Registro de transferencias por usuarios",
  "reports.users-chart.vaquita.sub-header": "Registro de vaquitas por usuarios",
  "reports.users-chart.extraction.sub-header":
    "Registro de extracciones por usuarios",
  "reports.users-chart.service.sub-header":
    "Registro de pago de servicios por usuarios",
  "reports.users-chart.qr.header": "Pagos con QR por usuarios",
  "reports.users-chart.qr.sub-header": "Registro de pagos con QR por usuarios",
  "reports.users.filter": "Filtrar usuarios por:",
  "reports-users-total.quantity-title": "Cantidad",
  "reports.users-total.description": "Usuarios mango",
  "reports.users-total.user-counter.title": "Contador de usuarios",
  "reports.users-total.user-counter.subtitle": "Billetera Mango",
  "reports.users.count": "{count} usuarios",
  "reports.users-total.total": "Total",
  "reports.users-total.frecuent-users": "Frecuentes",
  "reports.users-total.inactive-users": "Inactivos",
  "reports.users-total.active-users": "Activos",
  "reports.users-movements-done": "Movimientos realizados",
  "reports.errors.filter": "Filtrar errores por:",
  "reports.users-new.header": "Altas nuevas por mes",
  "reports.transactions.header": "Detalles de transacciones",
  "reports.services": "Servicios más recurrentes",
  "reports.details": "Detalles",
  "report.card-icon.class": "card-icon-{type}",
  "reports.credit-lines-reports.header": "Crédito Mango",
  "reports.credit-lines-reports.button-text-1": "Lista de Estados",
  "reports.credit-lines-reports.button-text-2": "Reporte de Créditos",
  "reports.credit-lines.filter-by-text": "Filtrado por",
  "reports.current-credits.widget-title": "Créditos vigentes",
  "reports.current-credits.widget-calification-title": "Calificación",
  "reports.current-credits.widget-user-qty-title": "Cantidad Usuarios",
  "reports.current-credits.widget-percentage": "%",
  "reports.credits-state.widget-title": "Estados de créditos",
  "reports.cancelled-transactions.widget-title": "Operaciones canceladas",
  "reports.cancelled-transactions.widget-cancelled": "Cancelados",
  "reports.grow-history.widget-title": "Historial de crecimiento",
  "reports.average-age.widget-title": "Promedio de edad",
  "reports.average-cities.widget-title": "Promedio por ciudades",
  "reports.credit-line-details.header": "Detalle de crédito",
  "reports.credit-line-details.sync-button-title": "SINCRONIZAR OPERACION",
  "reports.credit-line-history.title": "Historial de crédito",
  "reports.credit-line.filter-modal.define-amount": "Definir monto",
  "reports.credit-line.filter-modal.filter-by-state": "Filtrar por estado",
  "reports.credit-line.filter-modal.filter-by-operation":
    "Filtrar por tipo de operación",
  "reports.credit-line.filter-modal.operation-number": "N° de operación",
  "reports.credit-line.filter-modal.filter-by-amount-range": "Rango de monto",
  "reports.credit-line.filter-modal.filter-by-calification":
    "Filtrar por calificación",
  "reports.credit-line.filter-modal.title": "Filtrar por",
  "reports.credit-line-details.last-sync": "Última sincronización",
  "reports.credit-line-details.syncing": "Sincronizando...",
  "reports.credit-line-details.automatic-debit": "Débito automático",
  "reports.credit-line-details.credit-line-text": "Línea de crédito",
  "reports.credit-line-details.credit-used-text": "Utilizado",
  "reports.credit-line-details.credit-available-text": "Disponible",
  "reports.credit-line-details.document-number-text": "CI N°",
  "reports.credit-line-details.dues-details": "Detalle de cuotas",
  "reports.credit-line-details.total-to-pay": "Total a pagar",
  "reports.credit-line-averageCities.tooltip-title": "Ciudades",
  "reports.credit-line-averageAges.tooltip-title": "Edades",
  "reports.credit-line-average-tooltip.count": "Conteo",
  "reports.credit-line-assigned-line": "Línea asignada",
  "reports.credit-line-used-line": "Línea usada",
  "reports.credit-line-blocked-line": "Línea bloqueada",
  "reports.users-state-carousel.title": "Estado de usuarios",
  "reports.users-per-month.title": "Nuevos usuarios por mes",
  "reports.users-per-month.registers-left": "Quedaron en registro",
  "reports.users-per-month.new-registers": "Altas nuevas",
  "reports.users-per-month.canceled-registers": "Cancelados",
  "reports.users-per-month.total-registers": "Total",
  "reports.users-per-month.accordion-body-year": "Año {year}",
  "reports.users-per-month.accordion-header-no-data": "Sin datos 2023",
  "reports-user-report-form-title": "Parámetros de descarga",
  "reports.user-statement.form-title": "Parámetros",
  "reports-user-report-type": "Tipo de reporte",
  "reports-user-indicates-range": "Indica un rango de fecha",
  "reports-user.indicates-range": "Indicar un rango de fecha",
  "reports-user-range-date": "Rango de fecha",
  "reports-user-other-parameters": "Otros parámetros",
  "reports-user-obfuscate-user": "Ofuscar @User",
  "reports-user-allow-errors": "Permitir errores",
  "reports-user-exclude-report": "Excluir reporte",
  "reports.users.add-file-report":
    "Adjuntar Oficio del Ministerio Público (Opcional)",
  "reports.users.add-addressee": "Añadir destinatarios a CC (Opcional)",
  "reports.users.select-email": "Seleccionar correo",
  "reports.found.users.title": "Coincidencias encontradas",
  "reports.users.document": "Nro. de Documento",
  "reports.users.account-number": "Nro. de Cuenta",
  "reports.users.id": "ID de Usuario",
  "reports.users.phone-number": "Nro. de Teléfono",
  "reports.users.created-at": "Creación del Usuario",
  "reports-user-report-user-activity-form-title": "Ingresar parámetros",
  "reports.credit-lines.filters-date": "Fecha",
  "reports.credit-lines.filters-amount": "Monto",
  "reports.credit-lines.filters-calification": "Calificación",
  "reports.credit-lines.filters-operation": "Operación",
  "reports.credit-lines.filters-operation_number": "N° de operación",
  "reports.credit-lines.filters-amount_range": "Rango de monto",
  "reports.credit-lines.filters-state": "Estado",
  "reports.credit-lines.filters-document_number": "CI N°",
  "reports.credit-line-details.capital": "Capital",
  "reports.credit-line-details.iva": "IVA",
  "reports.credit-line-details.interest": "Interés",
  "reports.credit-line-details.expenses": "Gastos administrativos",
  "reports.credit-line-details.insurances": "Seguros",
  "reports.credit-line-details.tan": "T.A.N.",
  "reports.credit-line-details.tae": "T.A.E.",
  "reports.credit-line-details.dues-paidAt": "Pagado el {date}",
  "reports.credit-line-details.dues-number": "Pago {feeNumber} de {totalItems}",
  "reports.credit-line.date-filter-badge.text": "Desde: {from} hasta: {to}",
  "reports.user-statement.header": "Extracto de cuenta de usuario",
  "reports.credit-line-details.state-of-line": "Estado de la línea",
  "reports.credit-line-details.assigned-at": "Asignación",
  "reports.identity-verification.average-answer": "Respuesta promedio: {time}",
  "reports.users-report-filters": "Filtros de Reporte de Usuarios",
  "reports.errors-report": "Reporte de Errores",
  "reports.new-users-per-month": "Nuevos Usuarios por Mes",
  "reports.users-activity": "Actividad de Usuarios",
  "reports.users-state": "Estado de Usuarios",
  "reports.growth-history": "Historial de Crecimiento",
  "reports.average-age": "Promedio de edad",
  "reports.average-by-cities": "Promedio por ciudades",
  "reports.credit-line-details.mango-recharge-title": "Recarga Mango",
  "reports.credit-line.up-to-date-status": "Al día",
  "reports.credit-line.expired-status": "Vencido",
  "reports.credit-line.unassigned-status": "No asignada",
  "reports.credit-line.credits-operations-title": "Operaciones del crédito",
  "reports.credit-line.assigned-status": "Asignado",
  "reports.credit-line.credit-line-label": "Línea de crédito",
  "reports.credit-line.asignated-credit-line": "Línea asignada",
  "reports.marketing-transaction.header": "Transacciones de Marketing",

  /* 07. Executive */

  /* 08. Admin Panel */
  "admin-panel.configurations.header": "Configuraciones",
  "admin-panel.configurations.create-modal": "Crear Configuración",
  "admin-panel.configurations.edit-modal": "Editar Configuración",
  "admin-panel.configurations.delete-modal-title": "Eliminar Configuración",
  "admin-panel.configurations.delete-modal-body":
    "¿Estás seguro que deseas eliminar la Configuración seleccionada?",
  "admin-panel.dashboard.users.header": "Usuarios del Dashboard",
  "admin-panel.dashboard.roles.header": "Roles",
  "admin-panel.dashboard.create-modal": "Crear Usuario",
  "admin-panel.dashboard.edit-modal": "Editar Usuario",
  "admin-panel.dashboard.delete-modal-title": "Eliminar Usuario del Dashboard",
  "admin-panel.dashboard.delete-modal-body":
    "¿Estás seguro que deseas eliminar el usuario seleccionado?",
  "admin-panel.dashboard.roles.view-modal": "Ver Rol",

  /* 10. Development */
  "development.push-types.header": "Tipos de Push",
  "development.push-types.create-modal": "Crear Tipo de Push",
  "development.push-types.edit-modal": "Editar Tipo de Push",
  "development.push-types.delete-modal-title": "Eliminar Tipo de Push",
  "development.push-types.delete-modal-body":
    "¿Estás seguro que deseas eliminar el Tipo de Push seleccionado?",
  /* 11. Mango-Comercio */
  "mango-comercio.comercios.header": "Comercios",
  "mango-comercio.comercios.create-modal": "Añadir Comercio",
  "mango-comercio.comercios.edit-modal": "Editar Comercio",
  "mango-comercio.comercios.wizard.step1": "Datos de Comercio",
  "mango-comercio.comercios.wizard.sub-step1": "Datos de Comercio",
  "wizard.step-name-0": "Paso 1",
  "wizard.step-desc-0": "Tipo de comercio",
  "wizard.step-name-1": "Paso 2",
  "wizard.step-desc-1": "Responsable de la cuenta",
  "wizard.step-name-2": "Paso 3",
  "wizard.step-desc-2": "Datos del comercio",

  /*navtabs*/
  "tab.summary": "RESUMEN",
  "tab.transactions": "TRANSFERENCIAS",
  "tab.channels": "CANALES",
  "tab.channel-types": "TIPOS DE CANALES",
  "tab.information": "INFORMACIÓN",
  "tab.resend": "Reenvío",
  "tab.all-commerces": "Todos los comercios",
  "tab.selected": "Seleccionados",
  "tab.history": "Historial",
  "tab.activity": "Actividad",
  "tab.devices": "Dispositivos",
  "tab.black-list": "Lista negativa",

  /* mango administration page */
  "mango-users.edit-user-modal": "Editar Usuario",
  "mango-users.delete-user-modal-title": "Eliminar Usuario",
  "mango-users.delete-user-modal-body":
    "¿Estás seguro que deseas eliminar el usuario seleccionado?",
  "mango-users.edit-success": "Usuario editado exitosamente",
  "mango-users.get-users-error":
    "No se ha podido actualizar la lista de usuarios",
  "mango-users.delete-success": "Usuario eliminado exitosamente",
  "mango-users.delete-error":
    "No se ha podido eliminar el usuario seleccionado",

  "mango-users.total": "Total",
  "mango-users.active": "Activos",
  "mango-users.inactive": "Inactivos",
  "mango-users.blocked": "Bloqueados",

  /* mango user page */
  "mango-user.mango-data": "Datos de Mango",
  "mango-user.personal-data": "Datos Personales",

  /*channels*/
  "channels.edit-channel-modal": "Editar Canal",
  "channels.delete-channel-modal-title": "Eliminar Canal",
  "channels.delete-channel-modal-body":
    "¿Estás seguro que deseas eliminar este canal?",
  "channels.summary-channels-by-type": "Cantidad de canales por tipo",
  "channel-types.edit-channel-type-modal": "Editar Tipo de Canal",

  /*transactions*/
  "transactions.summary.transactions-title": "Transferencias",
  "transactions.summary.transactions-subtitle":
    "Transferencias hechas esta semana",
  "transactions.this-week": "Esta semana",
  "transactions.this-month": "Este mes",
  "transactions.transfer-order": "Pedido de dinero",
  "transactions.money-transfer": "Transferencia de dinero",
  "transactions.recharge": "Recarga de saldo",

  /* solicitud-de-vinculación */

  "device-binding-request.refer-to-fraud": "Derivar a fraudes",
  "device-binding-request.request-verified":
    "La solicitud ya ha sido verificada.\n ¿Deseas ver la siguiente? ",
  "device-binding-request.remove-from-fraud": "Quitar de fraudes",
  "device-binding-request.removing-from-fraud": "Quitando de fraudes...",
  "device-binding-request.remove-from-fraud.description":
    "¿Por qué estás quitando este caso de fraudes? \n De forma resumida ingresa el motivo. Esto ayudará \n en revisiones futuras.",
  "device-binding-request.reject-request": "Rechazar solicitud",
  "device-binding-request.rejecting-request": "Rechazando solicitud...",
  "device-binding-request.refering-to-fraud": "Derivando a fraude...",
  "device-binding-request.request-type": "Tipo de solicitud",
  "device-binding-request.request-date": "Fecha de solicitud",
  "device-binding-request.document": "Cédula",
  "device-binding-request.request-options": "Opciones de solicitudes",
  "device-binding-request.refer-to-fraud-tooltip": "Derivado a Fraudes",
  "device-binding-request.phone-number": "Nº de teléfono",
  "device-binding-request.prev-phone-number": "Nº de teléfono anterior",
  "device-binding-request.frontPhoto": "Foto cédula frontal",
  "device-binding-request.backPhoto": "Foto cédula dorsal",
  "device-binding-request.selfie": "Foto selfie",
  "device-binding-request.selfie-with-ci": "Foto Selfie con cédula",
  "device-binding-request.registry-date": "Fecha de aprobación de la foto",
  "device-binding-request.title": "Verificación de solicitud",
  "device-binding-request.dashboard-rejected-accounts":
    "Solicitudes rechazadas",
  "device-binding-request.dashboard-conditioned-accounts": "Rechazo temporal",
  "device-binding-request.dashboard-approved-accounts": "Solicitudes aprobadas",
  "device-binding-request.dashboard-pending_verification-accounts":
    "Solicitudes pendientes",
  "device-binding-request.dashboard-fraud-accounts": "Derivado a fraudes",
  "device-binding-request.list-page-title": "Lista de solicitudes",
  "devices-binding-request.tooltip-alert-risk": "Solicitud de alto riesgo",
  "devices-binding-request.derived-from-fraud": "Solicitud derivada a Fraudes",
  "devices-binding-request.rejected": "Solicitud rechazada",
  "devices-binding-request.verify-by": "Verificado por:",
  "device-binding-request.original-photo": "Foto original de registro",
  "device-binding-request.photo-backPhoto": "Foto dorsal de la solicitud",
  "device-binding-request.photo-frontPhoto": "Foto frontal de la solicitud",
  "device-binding-request.photo-selfie": "Foto selfie de la solicitud",
  "device-binding-request.photos-similarity": "Similitud entre fotos",
  "device-binding-request.trust": "Confianza",
  "device-binding-request.verifying-by": "Verificando por: {name}",
  "device-binding-request.reject-description":
    "¿Por qué estás rechazando esta solicitud?. \n De forma resumida ingresa un motivo de rechazo.\n Esto ayudará en revisiones futuras.",
  "device-binding-request.reject-title": "Rechazo de solicitud",
  "device-binding-request.block-device-permanently":
    "Bloquear dispositivo permanentemente",
  "device-binding-request.restrict-access": "Restringir acceso del usuario",
  "device-binding-reuqest.permit-access": "Permitir acceso al usuario",
  "device-binding-request.restrict-request-upload":
    "Evitar que el usuario continúe enviando solicitudes de vinculación",
  "device-binding-request.allow-request-creation":
    "Permitir que el usuario envíe nuevas solicitudes de inmediato.",
  "device-binding-request.derive.description":
    "¿Por qué estás derivando este usuario?. \n De forma resumida ingresa un motivo de la \n derivación. \nEsto ayudará en revisiones futuras.",
  "device-binding-request.empty.description":
    "No quedan más solicitudes de \n vinculación pendientes.",
  "device-binding-request.pending-request": "Solicitud pendiente",
  "device-binding-request.rejected-request": "Solicitud rechazada",
  "device-binding-request.temporal-rejection": "Rechazo temporal",
  "device-binding-request.approved-request": "Solicitud aprobada",
  "device-binding-request.device-and-number": "Dispositivo y número",
  "device-binding-request.current-phone": "Teléfono actual",
  "device-binding-request.request-type-2": "Tipo de Solicitud",
  "device-binding-request.creation-date": "Fecha de Creación",
  "device-binding-request.request-state": "Estado de la solicitud",
  "device-binding-request.request-state-2": "Estado de solicitud",
  "device-binding-request.requests-state": "Estado de solicitudes",
  "device-binding-request.derived-to-fraud": "Derivado a Fraude",
  "device-binding-request.waiting-time": "Tiempo de espera",
  "device-binding-request.rejected-by-ia": "Rechazado por IA",
  "device-binding-request.errors-details": "Detalles de errores",

  /* registration tracking */
  "registration-tracking.title": "Seguimiento de registro",
  "registration-tracking.users-list-title": "Lista de usuarios",
  "registration-tracking.levels-title": "Niveles de estado",
  "registration-tracking.users-label": "Usuarios",
  "registration-tracking.download-list-label": "Descargar listado .csv",
  "registration-tracking.invited-label": "Invitado",
  "registration-tracking.sms-label": "Verificar teléfono",
  "registration-tracking.users.view-details": "Ver detalles",
  "registration-tracking.user-detail.title": "Detalle estado de usuario",
  "registration-tracking.password-label": "Clave",
  "registration-tracking.tabs.errors-log": "Log de errores",
  "registration-tracking.filter-by-campaign-label":
    "Filtrar por campaña de referral",
  "registration-tracking.select-campaign-label": "Seleccione una campaña",
  "registration-tracking.registration-history.date-header": "Hora y fecha",
  "registration-tracking.number-header": "#",
  "registration-tracking.errors-log.type-header": "Tipo",
  "registration-tracking.errors-log.app-version": "Versión de la app",
  "registration-tracking.errors-log.date-header": "Fecha",
  "registration-tracking.user-detail.first-tab": "Seguimiento",
  "registration-tracking.user-detail.second-tab": " Gestión de fotos de C.I.",
  "registration-tracking.device.os-header": "Sistema Operativo",
  "registration-tracking.device.version-header": "Versión",
  "registration-tracking.device.usage-date-header": "Fecha de uso",
  "registration-tracking.device.playerID-header": "Player ID",
  "registration-tracking.photo-modal.title": "Foto de cédula",
  "registration-tracking.reload-button.error-message":
    "Hubo un problema al momento de cargar los datos",
  "registration-tracking.registration-history-title": "Historial de registro",
  "registration-tracking.registration-history-and-personal-data-title":
    "Historial de registro y datos personales",
  "registration-tracking.tabs.comments": "Comentarios",
  "registration-tracking.filter-by-label": "Filtrar por estados",
  "registration-tracking.nickname-label": "@Nickname",
  "registration-tracking.selfie-label": "Selfie con cédula",
  "registration-tracking.front-label": "Frontal de CI",
  "registration-tracking.back-label": "Dorsal de CI",
  "registration-tracking.additionaldata-label": "Datos adicionales",
  "registration-tracking.upload-photo-reinit-label": "Reinició subida de fotos",
  "registration-tracking.modify-user-data": "Modificar datos de usuario",
  "registration-tracking.levels-description":
    "Cada nivel representa el número de usuarios que se encuentran en proceso de registro",
  "registration-tracking.tabs.device": "Dispositivo",
  "registration-tracking.state-title": "Estado de registro",
  "registration-tracking.ci-edit-popover-text":
    "Este campo no se puede editar porque el usuario ya posee una cuenta en TU FINANCIERA",

  /* pages */
  "pages.orderby": "Ordenar por: ",
  "pages.no-data-notification": "No se encontraron datos",
  "pages.no-data": "No hay datos",
  "pages.pagination.label": "{startIndex}-{endIndex} de {totalItemCount}",
  "pages.pagination.label-2": "{pageNumber} de {numPages}",
  "pages.filterby": "Filtrar por: ",
  "pages.users-quantity": "Cantidad de usuarios",
  "pages.all-categories": "Todas las categorías",
  "pages.last-session": "Última sesión",
  "pages.account": "Cuenta",
  "pages.document": "Cédula",
  "pages.collaborator": "Colaborador",
  "pages.devices": "Dispositivos",
  "pages.data-and-photos": "Datos y fotos",
  "pages.creation": "Creación",
  "pages.errors": "Errores",
  "pages.user-statement": "Extracto",
  "pages.invalid-params": "Los parámetros ingresados no son válidos",
  "pages.search": "Buscar",
  "pages.credit": "Crédito",
  "pages.searchby": "Buscar por",
  "pages.search-commerce": "Buscar comercio",
  "pages.search-entity": "Buscar entidad",
  "pages.edit": "Editar",
  "pages.delete": "Eliminar",
  "pages.add-new": "AGREGAR NUEVO",
  "pages.order": "ORDENAR",
  "pages.display-options": "Mostrar Opciones",
  "pages.add-comment": "Agregar comentario",
  "pages.empty-comment": "No hay comentarios",
  "pages.no-device-info": "Sin datos del dispositivo",
  "pages.alt-empty-comment": "Sin comentarios",
  "pages.empty-list": "Lista vacía",
  "pages.empty-list-not-used": "Lista vacía, no se ha utilizado",
  "pages.empty-list-yet-credit-line": "aún la línea de crédito",
  "pages.tags-empty-list": "La lista de etiquetas se encuentra vacía",
  "pages.commerce-categories-empty-list":
    "La lista de categorías se encuentra vacía",
  "pages.commerce-empty-list": "La lista de comercios se encuentra vacía",
  "pages.promotions-empty-list": "La lista de promociones se encuentra vacía",
  "pages.empty-transactions": "No hay reportes de transacciones",
  "pages.send": "ENVIAR",
  "pages.startDate": "Desde",
  "pages.endDate": "Hasta",
  "pages.filter-date": "Filtrar por fechas",
  "pages.dates": "Fechas",
  "pages.filter-credit-line": "Filtrar por Línea de Crédito",
  "pages.apply": "APLICAR",
  "pages.clear-button": "RESTABLECER",
  "pages.actions": "Acciones",
  "pages.actualization": "Actualizar",
  "pages.block": "Bloquear",
  "pages.unblock": "Desbloquear",
  "pages.regenerate-password": "Regenerar contraseña",
  "pages.reactivate": "Reactivar",
  "pages.search-by-document-number": "Buscar por cédula",

  /* orderby */
  "orderby.categories": "Categoría:",
  "orderby.tags": "Etiqueta:",

  /* standard */
  "drop-files-here": "Soltá los archivos aquí.",
  "attach-file": "Adjuntar archivo",
  "standard.document": "Documento",
  "standard.required": "Solicitado",
  "standard.dues": "Cuotas",
  "standard.payed": "Pagado",
  "standard.user-not-found": "No se encontró el usuario",
  "standard.canceled": "Cancelado",
  "standard.services-payment": "Pago de servicios",
  "standard.qr-payment": "Pago QR",
  "standard.very-good": "Muy bueno",
  "standard.good": "Bueno",
  "standard.bad": "Malo",
  "standard.very-bad": "Muy malo",
  "standard.all": "Todas",
  "standard.no-calification": "Sin calificación",
  "standard.minimize": "Minimizar",
  "standard.maximize": "Maximizar",
  "standard.character-counter": "{count} Caracteres",
  "standard.profile-picture": "Foto de perfil",
  "standard.filter": "Filtro",
  "standard.hour": "Hora",
  "standard.attach-photo": "Adjuntar foto",
  "standard.view-map": "Ver mapa",
  "standard.view-all": "Ver todo",
  "standard.last-month": "Último mes",
  "standard.watch": "Ver",
  "standard.commerce": "Comercio",
  "standard.no-name": "Sin nombre",
  "standard.a-character": " a ",
  "standard.createdAt-label": " el {date}",
  "standard.user-profile": "Perfil de usuario",
  "standard.percentage": "{percent}%",
  "send-message": "Enviar mensaje",
  "standard.all-roles": "Todos los roles",
  "standard.role": "Rol",
  "bloqued-by-admin": "Bloqueado por Admin",
  "all-mango-users": "Todos los usuarios mango",
  calendar: "Calendario",
  "standard.show-and-hide": "Mostrar/ocultar",
  back: "Atrás",
  forward: "Adelante",
  "id-param.not-exist": "El parámetro id no existe",
  "percentage-text": "Porcentaje",
  "no-photo": "Sin foto",
  "amount-text": "Precio fijo",
  "default-document-title": "Mango - Back Office",
  "warning.title": "Atención!",
  "warning.must-verify-all-photos": "Debe verificar todas las fotos",
  "downloading-file.label":
    "Espere mientras se descarga el archivo. Esto podría tardar unos minutos.",
  "select-input.placeholder": "Seleccione...",
  "id-missing-error": "No se ha encontrado ningún id",
  "select.default-placeholder": "Seleccione una opción...",
  "last-update-by": "Última modificación por: {name}",
  users: "Usuarios",
  user: "Usuario",
  loading: "Cargando...",
  updating: "Actualizando...",
  "in-process": "En proceso",
  "no-options": "No hay opciones",
  new: "Nuevo",
  "blocked-device": "Dispositivo bloqueado",
  "standard.user-balance": "Balance del Usuario",
  "update-user-data": "Actualizar datos de usuario",
  "visualize-user-ci-photos": "Ver fotos de cédula del usuario",
  "visualize-ci-photos": "Ver fotos de cédula",
  male: "Masculino",
  female: "Femenino",
  ci: "Cédula de identidad",
  "creation-date": "Fecha de creación",
  "first-names": "Nombres",
  "last-names": "Apellidos",
  "first-names-and-last-names": "Nombres y Apellidos",
  telephone: "Teléfono",
  "trust-values": "Valores de confianza",
  "telephone-number": "Número de teléfono",
  "ci-abbreviation": "CI",
  "birth-date": "Fecha de nacimiento",
  nationality: "Nacionalidad",
  "view-detail": "Ver detalle",
  "current-state": "Estado actual",
  nickname: "Nickname",
  sex: "Sexo",
  active: "Activo",
  inactive: "Inactivo",
  download: "Descargar",
  deleted: "Eliminado",
  unused: "No utilizado",
  name: "Nombre",
  "full-name": "Nombre completo",
  "name-and-last-name": "Nombre y Apellido",
  "last-name": "Apellido",
  "full-last-name": "Apellido completo",
  state: "Estado",
  "request-history": "Historial de solicitud",
  states: "Estados",
  "all-states": "Todos los estados",
  permissions: "Permisos",
  "all-permissions": "Todos los permisos",
  operations: "Operaciones",
  "all-operations": "Todas las operaciones",
  visualize: "Ver",
  create: "Crear",
  administrator: "Administrador",
  description: "Descripción",
  "short-description": "Descripción corta",
  filters: "Filtros",
  date: "Fecha",
  "date-and-time": "Fecha y hora",
  duration: "Duración",
  motive: "Motivo",
  contact: "Contacto",
  "contact-name": "Nombre de contacto",
  category: "Categoría",
  value: "Valor",
  validation: "Validación",
  image: "Imagen",
  address: "Dirección",
  "business-name": "Razón Social",
  ruc: "RUC",
  color: "Color",
  "assign-to": "Asignar a",
  finished: "Finalizado",
  canceled: "Cancelado",
  rollback: "Reversa",
  error: "Error",
  "error-type": "Tipo de error",
  response: "Respuesta",
  "no-response": "Sin respuesta",
  id: "Id",
  type: "Tipo",
  amount: "Monto",
  destination: "Destino",
  photo: "Foto",
  detail: "Detalle",
  code: "Código",
  url: "URL",
  model: "Modelo",
  "operating-system": "Sistema operativo",
  version: "Versión",
  "player-id": "Player ID",
  "date-of-use": "Fecha de uso",
  "users-amount": "Cantidad de usuarios",
  cancel: "Cancelar",
  button: "Botón",
  number: "Número",
  colaborator: "Colaborador",
  excel: "Excel",
  frecuent: "Frecuente",
  "users-activity": "Actividad de usuario",
  "account-number": "Número de cuenta",
  "users-number-abb": "Nro. de usuarios",
  "user-id": "ID de usuario",
  "document-number": "Número de documento",
  "user-profile": "Perfil de usuario",
  verifieds: "Verificados",
  activation: "Activación",
  "ci-back": "Dorso de CI",
  selfie: "Selfie",
  "selfie-frontal": "Frontal Selfie",
  years: "Años",
  others: "Otros",
  general: "General",
  question: "Pregunta",
  content: "Contenido",
  "ask-question": "Formular pregunta",
  action: "Acción",
  "payment-date": "Fecha de pago",
  link: "Link",
  icon: "Icono",
  services: "Servicios",
  "category-name": "Nombre de categoría",
  resend: "Reenviar",
  channel: "Canal",
  automatic: "Automático",
  manual: "Manual",
  yes: "Si",
  "no-requests": "Sin solicitudes",
  no: "No",
  "creation-time": "Tiempo de creación",
  "account-state": "Estado de cuenta",
  verification: "Verificación",
  "verification-date": "Fecha de verificación",
  occupation: "Ocupación",
  city: "Ciudad",
  department: "Departamento",
  hour: "Hora",
  function: "Función",
  "users-placeholder": "@ruben,@carlos,@jonas",
  "users-placeholder-2": "@ruben, @carlos, @mango",
  message: "Mensaje",
  "click-to-see-details": "Click para ver detalles",
  tag: "Etiqueta",
  reject: "Rechazar",
  approve: "Aprobar",
  "less-than": "Menor a",
  "last-update": "Última actualización",
  "greater-than": "Mayor a",
  "less-than-or-equal": "Menor o igual a",
  "greater-than-or-equal": "Mayor o igual a",
  "equal-to": "Igual a",
  "not-equal-to": "Distinto a",
  exists: "Existe",
  "not-exists": "No existe",
  "api-versions": "Versiones de API",
  close: "Cerrar",
  reset: "Resetear",
  "alias-spi": "Alias SPI",
  "contradictory-options":
    "Estas opciones son contradictorias. Solo una puede estar activa.",
  "mango-user": "Usuario Mango",
  "mango-point-wendys": "Wendy's Villamorra",
  "mango-point-direction": "Villamora, Av. Defensores del Chaco",
  "no-changes": "Sin cambios",
  "not-verified": "No verificado",
  bind: "Vincular",
  "secure-device": "Dispositivo seguro",
  rotate: "Rotar",
  flip: "Voltear",
  none: "Ninguno",
  "visible-app": "Visible app",
  bank: "Banco",
  cooperative: "Cooperativa",
  financial: "Financiera",
  wallet: "Billetera",

  /* Dashboards */
  "dashboards.state-transfers": "Transferencias",
  "dashboards.state-vaquitas": "Vaquitas",
  "dashboards.state-recharges": "Recargas",

  /* Badges */
  "badge-blocked": "BLOQUEADO",
  /* ALTS */
  "alts.mango-logo": "Logotipo de Mango",
  "alts.users-icon": "Icono de usuarios",
  "alts.ghost-icon": "Icono de fantasma",
  "alts.user-profile": "Perfil de usuario",
  "alt.preview-images": "Vista previa de imágenes",
  "alt-preview-user-card": "Perfil de usuario",
  "alt.document-icon": "Icono de documento",
  "alt.icon": "Icono",
  "alt.botton-icon": "Icono del botón",
  "alt.users-icon": "Icono de usuarios",
  "alt.logo": "Logo",
  "alt.mango-logo": "Logo de Mango",
  "alt.arrow-down": "Flecha hacia abajo",
  "alt.rotating-arrow": "Flecha rotativa",
  "alt.photo": "Foto",
  "alt.pdf-mayus": "PDF",
  "alt.close-icon": "Icono de cerrar",
  "alt.warning-icon": "Icono de advertencia",
  "alt.marker": "Marcador",
  "alt.smartphone-image": "Imagen de un smartphone",
  "alt.hammer-icon": "Icono de martillo",
  "alt.restrict-access-icon": "Icono de restringir acceso",
  "alt.permit-access-icon": "Icono de permitir acceso",
  "alt.fraud-icon": "Icono de fraude",
  "alt.mango-point": "Punto Mango",
  "alt.ci-photo": "Foto de cédula",
  "alt.display-icon": "Icono de desplegar",
  "alt.mango-logotype": "Logotipo de Mango",
  "alt.phantom-icon": "Icono de fantasma",
  "alt.evidence": "Evidencia",
  "alt.send-image": "Enviar imagen",
  "alt.send-message": "Enviar mensaje",
  "alt.manage-days": "Administrar días",
  "alt.calendar": "Calendario",
  "alt.whatsapp": "WhatsApp",
  "alt.pdf": "Pdf",
  "alt.check": "Check",
  "alt.user-photo": "Foto de usuario",

  /* Months */
  january: "Enero",
  february: "Febrero",
  march: "Marzo",
  april: "Abril",
  may: "Mayo",
  june: "Junio",
  july: "Julio",
  august: "Agosto",
  september: "Septiembre",
  october: "Octubre",
  november: "Noviembre",
  december: "Diciembre",

  /* Days abbreviaton */
  sun: "Dom",
  mon: "Lun",
  tue: "Mar",
  wed: "Mie",
  thu: "Jue",
  fri: "Vie",
  sat: "Sab",

  /* Titles */
  "home-title": "Inicio | Mango - Back Office",
  "colaborators-title": "Colaboradores | Mango - Back Office",
  "tickets-title": "Tickets | Mango - Back Office",
  "users-title": "Usuarios | Mango - Back Office",
  "marketing-title": "Marketing | Mango - Back Office",
  "shop-title": "Comercios | Mango - Back Office",
  "integrations-title": "Integraciones | Mango - Back Office",
  "reports-title": "Reportes | Mango - Back Office",
};
