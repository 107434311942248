import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

import {
  deleteCurrentUserInformationHandler,
  getCurrentUserRoleSuccessHandler,
  setAuthInformationHandler,
  setChangePasswordParamsHandler,
  setCurrentUserAuthStateHandler,
  setCurrentUserInformationHandler,
} from "./handlers";

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    getCurrentUserRoleSuccess: getCurrentUserRoleSuccessHandler,
    setCurrentUserInformation: setCurrentUserInformationHandler,
    deleteCurrentUserInformation: deleteCurrentUserInformationHandler,
    setAuthInformation: setAuthInformationHandler,
    setCurrentUserAuthState: setCurrentUserAuthStateHandler,
    setChangePasswordParams: setChangePasswordParamsHandler,
  },
});

export const {
  getCurrentUserRoleSuccess,
  setCurrentUserInformation,
  deleteCurrentUserInformation,
  setAuthInformation,
  setCurrentUserAuthState,
  setChangePasswordParams,
} = userSlice.actions;

export const UserActionTypes = {
  SET_CURRENT_USER_AUTH_STATE: setCurrentUserAuthState.type,
  DELETE_CURRENT_USER_INFORMATION: deleteCurrentUserInformation.type,
  GET_CURRENT_USER_ROLE_SUCESS: getCurrentUserRoleSuccess.type,
  SET_CHANGE_PASSWORD_PARAMS: setChangePasswordParams.type,
  SET_CURRENT_USER_INFORMATION: setCurrentUserInformation.type,
  SET_AUTH_INFORMATION: setAuthInformation.type,
};

export default userSlice.reducer;
