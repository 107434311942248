import { InitialStateType } from "./types";

export const initialState: InitialStateType = {
  email: "",
  firstName: "",
  lastName: "",
  token: "",
  refreshToken: "",
  expiration: "",
  temporalPassword: false,
  picture: "",
  _id: "",
  role: {
    _id: "",
    name: "",
    permissions: [],
    profilePic: "",
  },
  authState: "signIn",
  idToken: "",
  urlId: "",
  urlToken: "",
};

export default initialState;
